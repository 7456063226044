import {
  CompanyInformation,
  MailingAddress,
  OwnerInformation,
} from "../api/types";

export const validateCompanyInfo = (
  info: Partial<CompanyInformation>,
  isUs: boolean = false
): info is CompanyInformation => {
  return (
    !info.companyName ||
    !info.companyName2 ||
    !info.companyName3 ||
    !info.entityEnding ||
    !info.industry ||
    !info.capital ||
    !info.currency ||
    !info.description ||
    (!info.appliedStates && isUs)
  );
};

export const validateOwnersInfo = (
  owners: Partial<OwnerInformation>[],
  isDirector: boolean = false
): owners is OwnerInformation[] => {
  let result =
    (isDirector && owners.length === 0) ||
    owners.some((owner) => {
      const isIndividual = owner.type === "Individual" || isDirector;
      return (
        !owner.passportImagesIdCard ||
        owner?.passportImagesIdCard?.length === 0 ||
        !owner.document ||
        owner?.document?.length === 0 ||
        (!owner.ownership && !isDirector) ||
        !owner.registeredAddress ||
        !owner.zipCode ||
        !owner.nationality ||
        (isIndividual &&
          (!owner.firstName ||
            !owner.lastName ||
            !owner.idNumber ||
            !owner.dateOfBirth ||
            !owner.contactNumber ||
            !owner.shareholderExpiryDate ||
            !owner.email)) ||
        (!isIndividual &&
          (!owner.companyName ||
            !owner.businessNumber ||
            !owner.authorizedPerson))
      );
    });
  const totalShare = owners.reduce((acc, cur) => acc + (cur.ownership ?? 0), 0);
  if ((totalShare !== 100 && !isDirector) || (isDirector && totalShare > 100))
    result = true;

  return result;
};

export const validateMailingAddress = (
  data: Partial<MailingAddress>
): data is MailingAddress => {
  return (
    (!data.country || !data.city || !data.address || !data.zipCode) &&
    Boolean(!data.isUsingDrmsAddress)
  );
};
