import React from "react";
import { callApiRequestQuotation } from "src/api/service";
import { IconSelectCard } from "src/components/icons";
import { Service } from "src/pages/ServicesContent/ServicesContent";

type ServiceCardProps = {
  isSelected: boolean;
  service: Service;
  onSelect: (id: number) => void;
  serviceType: string;
  serviceCycle: number[];
  refetch: () => void;
};

export default function ServiceCard(props: ServiceCardProps) {
  const currentCycle =
    props.service.serviceCycle[props.service.cycleNumber - 1] ??
    props.service.serviceCycle[props.service.serviceCycle.length - 1];
  const needSendRequestQuotation =
    props.service.isQuotation && !currentCycle.pricePerCycleQuotation;

  const isReceivedQuotation =
    props.service.isQuotation && currentCycle?.pricePerCycleQuotation;

  const handleSelectCard = () => {
    if (needSendRequestQuotation) return;
    props.onSelect(props.service.id);
  };

  const handleSendRequestQuotation = async () => {
    await callApiRequestQuotation(props.service.id, props.service.cycleNumber);
    props.refetch();
  };

  return (
    <div
      className={`relative w-full rounded-2xl border px-3 md:px-8 py-3 md:py-[22px] md:pr-6 md:pl-14
        ${props.isSelected ? "border-primary" : "border-gray-300"}
        ${!needSendRequestQuotation ? "cursor-pointer" : ""}
      `}
      onClick={handleSelectCard}
    >
      {props.isSelected && (
        <IconSelectCard className={"absolute -top-[1px] left-0"} />
      )}
      <p className={"text-cLg font-bold sm:text-h4"}>{props.service.label}</p>
      <div className="flex mt-3 gap-1 md:mt-0">
        <div className={"flex flex-col gap-3 md:pt-6 flex-1 md:flex-[2]"}>
          <div>
            <div>
              <p className={"sm:text-lg"}>{props.service.description}</p>
              <ul
                className={
                  "flex list-disc flex-col gap-2 pl-8 text-violet sm:flex-row sm:gap-x-12 sm:gap-y-1 mt-3 flex-wrap"
                }
              >
                {props.service?.agents?.map((agent, index) => (
                  <li key={agent + index}>
                    <span>{agent}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          className={
            `flex flex-col items-end justify-between md:py-6 text-orange ${needSendRequestQuotation ? '' : 'pt-2'} md:pt-0 md:flex-1`
          }
        >
          {(!props.service.isQuotation || isReceivedQuotation) && (
            <span className={"text-[20px] md:text-[22px] md:text-h4 font-bold text-nowrap"}>
              {props.service.currency}{" "}
              {!props.service.isQuotation
                ? props.service.price
                : currentCycle?.pricePerCycleQuotation}
            </span>
          )}
          {needSendRequestQuotation && (
            <span className={"text-[20px] md:text-h4 font-bold text-primary"}>
              From {props.service.currency} {props.service.price}
            </span>
          )}
          {needSendRequestQuotation &&
            !props.service.isQuotationSendRequest && (
              <span
                className="mb-1 cursor-pointer text-primary underline"
                onClick={handleSendRequestQuotation}
              >
                {"Click here to request quotation"}
              </span>
            )}
          {needSendRequestQuotation && props.service.isQuotationSendRequest && (
            <span className="mb-1 font-bold">{"Quotation Requested"}</span>
          )}
          {isReceivedQuotation && (
            <span className="mb-1 font-bold text-green-400">
              {"Quotation Received"}
            </span>
          )}
          <div className="flex flex-col md:flex-row gap-2 md:gap-4 text-disable/85">
            <div className="rounded border border-solid px-2">
              {props.serviceType}
            </div>
            {props.serviceType !== "Based" && (
              <div className="rounded border border-solid px-2">
                For Cycle {props.serviceCycle.join(" ")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
