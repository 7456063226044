import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { callApiCreateAccount, callApiSendEmailOTP } from "src/api/account";
import { ApiVerifyEmail, NationValue } from "src/api/types";
import { DialogContainer } from "src/components/DialogContainer";
import {
  DialogFailureFullscreen,
  DialogRequestingFullscreen,
  DialogSuccessFullscreen,
} from "src/components/DialogFormStatusFullscreen";
import { FormFieldEmail } from "src/components/FormFieldEmail";
import { FormFieldOtp } from "src/components/FormFieldOtp";
import { FormFieldPassword } from "src/components/FormFieldPassword";
import { FormFieldPhoneNumber } from "src/components/FormFieldPhoneNumber";
import { FormFieldSelect } from "src/components/FormFieldSelect";
import { FormFieldText } from "src/components/FormFieldText";
import {
  IconArrowLeft,
  IconSpinner,
  IconArrowCircle,
} from "src/components/icons";
import { NATION_INFOS } from "src/constants/SelectionOptions";
import { useValidateCaller } from "src/hooks-ui/useValidateCaller";
import { PageLayoutOneForm } from "src/layouts/PageLayoutOneForm";
import {
  generateRegisterParam,
  RNPhoneValue,
} from "src/services-business/api/generate-api-param/account";
import { FormStatus } from "src/types/common";
import { RoutePaths } from "src/constants/routerPaths";
import { cn } from "src/utils/cn.util";

export function RegisterPage() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [stepIndex, setStepIndex] = useState<number>(1);
  const {
    validateCaller: validateNationStepCaller,
    validateAll: validateNationStepAll,
  } = useValidateCaller();
  const [nation, setNation] = useState<NationValue>();
  // step 2
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<RNPhoneValue>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  // step 3
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  // step 4
  const [emailOtp, setEmailOtp] = useState<string>("");

  const [status, setStatus] = useState<FormStatus>("typing");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const SelectNationStepIndex = 1;
  const AccountInformationStepIndex = 2;
  const CreateAccountStepIndex = 3;
  const EnterOtpStepIndex = 4;
  const FirstStep = SelectNationStepIndex;
  const LastStep = EnterOtpStepIndex;

  function handleClickNextStep() {
    if (stepIndex < LastStep) {
      setStepIndex(stepIndex + 1);
    }
  }
  function handleClickBackStep() {
    if (stepIndex > FirstStep) {
      setStepIndex(stepIndex - 1);
    }
  }
  function handleChangeNation(nation: NationValue) {
    setNation(nation);
  }

  function handleClickNextAtNation() {
    if (validateNationStepAll()) {
      handleClickNextStep();
    }
  }

  async function handleClickCreateAccount() {
    if (
      !nation ||
      !email ||
      !phone ||
      !password ||
      !rePassword ||
      !firstName ||
      !lastName
    ) {
      return;
    }
    setStatus("requesting");
    const createAccountParam = generateRegisterParam(
      nation,
      email,
      phone,
      firstName,
      lastName,
      password,
      rePassword,
      emailOtp
    );
    try {
      await callApiCreateAccount(createAccountParam);
      setStatus("success");
    } catch (e: unknown) {
      setStatus("failure");
      setErrorMessage(e?.toString());
      console.error(e);
    }
  }

  function handleClickBackToLogin() {
    navigate(RoutePaths.login);
  }

  return (
    <PageLayoutOneForm>
      <p className="text-h4 text-center">
        {translation.t("Launch your new in", { companyType: "Company" })}
      </p>
      <FormFieldSelect
        id={"nationSelect"}
        isRequired
        placeholder={translation.t("Choose nation")}
        isFixedValue={stepIndex !== 1}
        value={nation}
        optionInfos={NATION_INFOS}
        onChange={handleChangeNation}
        validateCaller={validateNationStepCaller}
        className={cn({
          "font-semibold": stepIndex !== 1,
        })}
      />
      {stepIndex === SelectNationStepIndex && (
        <button
          onClick={handleClickNextAtNation}
          className="h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
        >
          {translation.t("Continue")}
        </button>
      )}
      {stepIndex === AccountInformationStepIndex && (
        <AccountInformationStep
          onClickNextStep={handleClickNextStep}
          onClickPreviousStep={handleClickBackStep}
          email={email}
          phone={phone}
          firstName={firstName}
          lastName={lastName}
          setEmail={setEmail}
          setPhone={setPhone}
          setFirstName={setFirstName}
          setLastName={setLastName}
        />
      )}
      {stepIndex === CreateAccountStepIndex && (
        <CreateAccountStep
          onClickCreateAccount={handleClickNextStep}
          onClickPreviousStep={handleClickBackStep}
          firstName={firstName || ""}
          lastName={lastName || ""}
          email={email || ""}
          password={password}
          setPassword={setPassword}
          rePassword={rePassword}
          setRePassword={setRePassword}
        />
      )}
      {stepIndex === EnterOtpStepIndex && (
        <EmailOtpStep
          firstName={firstName || ""}
          lastName={lastName || ""}
          email={email}
          onChangeOtp={setEmailOtp}
          onClickVerifyAccount={handleClickCreateAccount}
          onClickPreviousStep={handleClickBackStep}
        />
      )}
      {status === "requesting" && <DialogRequestingFullscreen />}
      {status === "success" && (
        <DialogSuccessFullscreen
          title={"Account successfully created"}
          subTitle={"Start your journey with us!"}
          onClose={handleClickBackToLogin}
          actionElement={
            <button
              onClick={handleClickBackToLogin}
              className="w-full h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
            >
              <IconArrowCircle className={"text-white"} />
              <span>{translation.t("Back to Log in")}</span>
            </button>
          }
        />
      )}
      {status === "failure" && (
        <DialogFailureFullscreen
          title="Failure!"
          subTitle={errorMessage || "Could not create account"}
          actionElement={
            <div
              className={"flex flex-col gap-y-2 justify-center items-center"}
            >
              <button
                onClick={() => setStatus("typing")}
                className="w-full min-w-[300px] h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
              >
                <span>{translation.t("Close")}</span>
              </button>
            </div>
          }
        />
      )}
    </PageLayoutOneForm>
  );
}

type AccountInformationStepProps = {
  onClickNextStep: () => void;
  onClickPreviousStep: () => void;
  email: string | undefined;
  setEmail: (value: string) => void;
  firstName: string | undefined;
  setFirstName: (value: string) => void;
  lastName: string | undefined;
  setLastName: (value: string) => void;
  phone: RNPhoneValue | undefined;
  setPhone: (value: RNPhoneValue) => void;
};
function AccountInformationStep(props: AccountInformationStepProps) {
  const translation = useTranslation();
  const { validateCaller, validateAll } = useValidateCaller();

  function handleClickNext() {
    if (validateAll()) {
      props.onClickNextStep();
    }
  }

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-col w-fit gap-y-2">
        <p className="font-bold">{translation.t("Account information")}</p>
        <div className="w-1/2 border border-primary"></div>
      </div>
      <FormFieldEmail
        id="accountEmail"
        isRequired
        value={props.email}
        onChange={props.setEmail}
        validateCaller={validateCaller}
        shouldLiveCheck
      />
      <FormFieldPhoneNumber
        id={"phoneNumber"}
        placeholder={"Input number"}
        isRequired
        value={props.phone}
        onChange={props.setPhone}
        validateCaller={validateCaller}
        shouldLiveCheck
      />
      <div className={"w-full flex gap-4"}>
        <FormFieldText
          id={"FirstName"}
          isRequired
          label="First Name"
          value={props.firstName}
          onChange={props.setFirstName}
          placeholder="Enter first name"
          validateCaller={validateCaller}
        />
        <FormFieldText
          id={"LastName"}
          isRequired
          label="Last Name"
          value={props.lastName}
          onChange={props.setLastName}
          placeholder="Enter last name"
          validateCaller={validateCaller}
        />
      </div>
      <button
        onClick={handleClickNext}
        className="h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
      >
        {translation.t("Next")}
      </button>
      <div className="flex w-full justify-center">
        <button
          onClick={props.onClickPreviousStep}
          className="flex items-center w-fit text-gray-400 text-sm gap-1 px-1"
        >
          <IconArrowLeft />
          <span>{translation.t("Previous step")}</span>
        </button>
      </div>
    </div>
  );
}

type CreateAccountStepProps = {
  onClickCreateAccount: () => void;
  onClickPreviousStep: () => void;
  firstName: string;
  lastName: string;
  email: string;
  password: string | undefined;
  setPassword: (value: string) => void;
  rePassword: string | undefined;
  setRePassword: (value: string) => void;
};
function CreateAccountStep(props: CreateAccountStepProps) {
  const translation = useTranslation();
  const { validateCaller, validateAll } = useValidateCaller();
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(true);

  function handleClickNext() {
    if (props.password !== props.rePassword) {
      setIsPasswordMatch(false);
      return;
    }
    if (validateAll()) {
      const param: ApiVerifyEmail = {
        email: props.email,
        firstName: props.firstName,
        lastName: props.lastName,
      };
      callApiSendEmailOTP(param).catch((e) => console.error(e));
      props.onClickCreateAccount();
    }
  }

  function handleChangeRePassword(rePass: string) {
    props.setRePassword(rePass);
    setIsPasswordMatch(true);
  }

  return (
    <DialogContainer isCloseOnClickOverlay={false}>
      <div className="w-full justify-center items-center py-8 px-4 flex flex-col">
        <div className="w-full max-w-[400px] mx-4 flex flex-col gap-y-8">
          <div className="flex flex-col gap-y-2">
            <p className="text-h4 text-center">
              {translation.t("Create account")} ?
            </p>
            <p className="text-cBase">
              {translation.t(
                "To start your journey, enter your password in the box below"
              )}
              !
            </p>
          </div>
          <FormFieldPassword
            id={"password"}
            label={"New password"}
            isRequired
            value={props.password}
            onChange={props.setPassword}
            validateCaller={validateCaller}
          />
          <div className={"space-y-2"}>
            <FormFieldPassword
              id={"rePassword"}
              label={"Re-enter password"}
              isRequired
              value={props.rePassword}
              onChange={handleChangeRePassword}
              onEnter={handleClickNext}
              validateCaller={validateCaller}
            />
            {!isPasswordMatch && (
              <p className={"text-danger"}>
                {translation.t("The entered passwords do not match")}!
              </p>
            )}
          </div>
          <button
            onClick={handleClickNext}
            className="h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
          >
            {translation.t("Create account")}
          </button>
          <div className="flex w-full justify-center">
            <button
              onClick={props.onClickPreviousStep}
              className="flex items-center w-fit text-gray-400 text-sm gap-1 px-1"
            >
              <IconArrowLeft />
              <span className="font-semibold">
                {translation.t("Previous step")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </DialogContainer>
  );
}

type Props = {
  firstName: string;
  lastName: string;
  email: string | undefined;
  onChangeOtp: (otp: string) => void;
  onClickVerifyAccount: () => void;
  onClickPreviousStep: () => void;
};

export function EmailOtpStep(props: Props) {
  const translation = useTranslation();
  const [isResending, setIsResending] = useState<boolean>(false);
  const MaxCountDown = 180;
  const [disableCountDown, setDisableCountDown] =
    useState<number>(MaxCountDown);
  const actionBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (disableCountDown > 0) {
      setTimeout(() => {
        setDisableCountDown((i) => (i >= 1 ? i - 1 : 0));
      }, 1000);
    }
  }, [disableCountDown]);

  async function handleClickResendOtp() {
    if (!props.email || disableCountDown > 0) {
      return;
    }
    setIsResending(true);
    try {
      const param: ApiVerifyEmail = {
        email: props.email,
        firstName: props.firstName,
        lastName: props.lastName,
      };
      const event = new Event("ResendOTP");
      document.dispatchEvent(event);
      await callApiSendEmailOTP(param).catch((e) => console.error(e));
      setDisableCountDown(MaxCountDown);
      setIsResending(false);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <DialogContainer isCloseOnClickOverlay={false}>
      <div className="w-full justify-center items-center py-8 px-4 flex flex-col">
        <div className="w-full max-w-[400px] mx-4 flex flex-col gap-y-8">
          <p className={"text-h3 text-center"}>
            {translation.t("Email Verification")}
          </p>
          <div>
            <p className={"text-center"}>
              {translation.t("We have sent code to your email")}:
            </p>
            <p className={"text-center"}>{props.email}</p>
          </div>
          <FormFieldOtp
            otpLength={4}
            onInputOtp={props.onChangeOtp}
            onLastOtp={() => actionBtnRef.current?.focus()}
          />
          <p className={"flex flex-row justify-center gap-1"}>
            <span>{translation.t("Didn’t receive code")}?</span>
            {disableCountDown <= 0 ? (
              <span
                onClick={handleClickResendOtp}
                className={"font-bold cursor-pointer text-primary"}
              >
                {translation.t("Resend")}
              </span>
            ) : (
              <span className={"font-bold text-primary cursor-not-allowed"}>
                {translation.t("Resend")}
              </span>
            )}
            {isResending && <IconSpinner />}
          </p>
          {disableCountDown > 0 && (
            <div className="text-center">
              OTP code is valid within {disableCountDown} seconds
            </div>
          )}
          {disableCountDown === 0 && (
            <div className="text-center text-[red]">
              The OTP has expired. Please try again!
            </div>
          )}
          <button
            ref={actionBtnRef}
            onClick={props.onClickVerifyAccount}
            className="h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
          >
            {translation.t("Verify account")}
          </button>
          <div className="flex w-full justify-center">
            <button
              onClick={props.onClickPreviousStep}
              className="flex items-center w-fit text-gray-400 text-sm gap-1 px-1"
            >
              <IconArrowLeft />
              <span>{translation.t("Previous step")}</span>
            </button>
          </div>
        </div>
      </div>
    </DialogContainer>
  );
}
