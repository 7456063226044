import { createContext } from "react";
import React, { useState } from "react";
import { ListCyclesOb, MyServiceStepType } from "../../../api/types";

interface AppContextInterface {
  detailFilling: MyServiceStepType | null;
  listCycles: ListCyclesOb | undefined;
  setDetailFilling: React.Dispatch<React.SetStateAction<MyServiceStepType>>;
  setListCycles: React.Dispatch<React.SetStateAction<ListCyclesOb>>;
}

const initialAppContext: AppContextInterface = {
  detailFilling: null,
  listCycles: undefined,
  setDetailFilling: () => null,
  setListCycles: () => null,
};

export const LLCMyServiceContext = createContext(initialAppContext);

export const LLCMyServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [detailFilling, setDetailFilling] = useState<MyServiceStepType>(
    initialAppContext.detailFilling as MyServiceStepType
  );
  const [listCycles, setListCycles] = useState<ListCyclesOb>(initialAppContext.listCycles as ListCyclesOb);
  return (
    <LLCMyServiceContext.Provider
      value={{
        detailFilling,
        listCycles,
        setDetailFilling,
        setListCycles,
      }}
    >
      {children}
    </LLCMyServiceContext.Provider>
  );
};
