import React, { ButtonHTMLAttributes } from "react";
import { IconSpinner } from "./icons";
import { cn } from "../utils/cn.util";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  icon?: React.ReactNode;
}

export default function ButtonCs(props: ButtonProps) {
  const { isLoading, className, disabled, children, icon, ...rest } = props;
  const newClassName = disabled
    ? " cursor-not-allowed bg-primary/30 " + className
    : className;
  return (
    <button
      className={cn(
        " flex justify-center items-center bg-primary text-white font-semibold rounded-lg py-2 px-4",
        newClassName
      )}
      disabled={disabled}
      {...rest}
      type="submit"
    >
      <span>{isLoading && <IconSpinner className="w-4 h-4 mr-2" />}</span>
      {icon && <span className="mr-2">{icon}</span>}
      <span className="truncate">{children}</span>
    </button>
  );
}
