import { useContext, useEffect, useRef, useState } from "react";
import { useSubscription } from "react-stomp-hooks";
import {
  DataMsg,
  callApiGetChannel,
  callApiGetMessages,
  callApiSendMessage,
} from "src/api/chat";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { uniqBy } from "src/utils/base.util";

export type Message = {
  id: string;
  text: string;
  isMe: boolean;
  sender?: string | null;
  time: string;
  type: string;
  data?: DataMsg[];
};

type UseChatProps = {
  onMessage?: () => void;
};

export function useChat({ onMessage }: UseChatProps = {}) {
  const { user } = useContext(AuthContext);

  const [userId, setUserId] = useState<string>();
  const [channelId, setChannelId] = useState<string>();
  const [messages, setMessages] = useState<Message[]>();
  const [loading, setLoading] = useState(false);
  const [startMessageTimeStamp, setStartMessageTimeStamp] = useState(0);

  const canFetchMsg = useRef(true);

  const fetchChannel = async () => {
    if (channelId) return;
    const { _id, name } = (await callApiGetChannel()) ?? {};
    setChannelId(_id);
    setUserId(name);
  };

  const fetch = async (offset = 0, currentIdChannel = "") => {
    if (offset !== 0 && (!canFetchMsg.current || loading)) return;

    if (!channelId && !currentIdChannel) {
      fetchChannel();
      return;
    }

    setLoading(true);

    const res = await callApiGetMessages({
      roomId: channelId ?? currentIdChannel,
      offset,
      count: 10,
    });
    const { messages = [] } = res;
    const startMessTs = messages.find(
      (mess) => mess.alias === "START_CHAT"
    )?.ts;
    if (startMessTs) {
      const startTime = new Date(startMessTs);
      if (!startMessageTimeStamp || startMessageTimeStamp < startTime.valueOf())
        setStartMessageTimeStamp(startTime.valueOf());
      canFetchMsg.current = false;
    }

    const convertedMessages = messages
      .filter((mess) => new Date(mess.ts).valueOf() > startMessageTimeStamp)
      .map((item) => ({
        id: item._id,
        text: item.msg,
        isMe: item.alias === user?.email,
        sender: item.alias,
        time: item._updatedAt,
        type: item.type,
        data: item.data,
      }));

    setMessages((prev) => {
      const uniqMessages = uniqBy(
        [...convertedMessages, ...(prev ?? [])],
        (item) => item.id
      );
      uniqMessages.sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
      );

      return [...uniqMessages];
    });

    setLoading(false);
  };

  const sendMessage = async (text: string) => {
    let id = channelId ?? "";
    if (!id) {
      const { _id, name } = (await callApiGetChannel()) ?? {};
      id = _id;
      setChannelId(_id);
      setUserId(name);
    }
    await callApiSendMessage(text, id);
    fetch(0, id);
  };

  const fetchMore = () => fetch(messages?.length ?? 0);
  const refresh = () => {
    setMessages([]);
    fetch(0);
  };

  useEffect(() => {
    fetch(0);
  }, [channelId]);

  useSubscription(`/chat/topic/public/${userId}`, () => {
    fetch(0).then(onMessage);
  });

  return { sendMessage, messages, fetchMore, refresh, loading };
}
