import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useApiGetOrderPayment } from "src/hooks-api/useOrderPayment";
import { v4 as uuidv4 } from "uuid";
import { generateFormatDate } from "src/utils/base.util";
import styled from "@emotion/styled";

const Table = styled(DataGrid)`
  .MuiDataGrid-cell {
    overflow: visible !important;
  }
` as typeof DataGrid;

const OrderPaymentContent = () => {
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const { data, refetch } = useApiGetOrderPayment({
    page: paginationModel.page,
    size: paginationModel.pageSize,
  });

  const dataTable = data?.content || [];
  const orderPaymentColumns: GridColDef[] = [
    {
      field: "",
      headerName: "No.",
      sortable: false,
      type: "string",
      flex: 1,
      minWidth: 70,
      renderCell: (params) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.id) + 1;
      },
    },
    { field: "orderId", headerName: "Order ID", width: 200 },
    {
      field: "statusPayment",
      headerName: "Status",
      sortable: false,
      flex: 2,
      minWidth: 120,
      renderCell: (params) => {
        const status = params.row.statusPayment;
        let styleClass = "rounded-lg flex justify-center items-center h-full";
        switch (status) {
          case "Rejected":
            styleClass =
              styleClass + " bg-[rgba(218,_16,_4,_0.15)] text-[#DA1004]";
            break;
          case "Approved":
            styleClass =
              styleClass +
              " bg-[rgba(7,_164,_97,_0.15)] text-[rgba(7,_164,_97,_1)]";
            break;
          case "Pending":
            styleClass =
              styleClass +
              " bg-[rgba(93,_80,_198,_0.15)] text-[rgba(93,_80,_198,_1)]";
            break;
          default:
            break;
        }
        return (
          <div className="2xl:w-[60%] p-1 h-full">
            <p className={styleClass}>{status}</p>
          </div>
        );
      },
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      sortable: false,
      type: "string",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className="w-full relative group">
            <div
              className={
                "absolute hidden group-hover:block bg-white rounded p-3 z-50 shadow " +
                (params.api.getRowIndexRelativeToVisibleRows(params.id) >
                (dataTable.length || 0) / 2
                  ? "bottom-6"
                  : "top-6")
              }
            >
              <table className="table-auto border-collapse border border-slate-500 rounded">
                <thead>
                  <tr>
                    <th className="border border-slate-300 p-2">
                      {translation.t("Service ID")}
                    </th>
                    <th className="border border-slate-300 p-2">
                      {translation.t("Service Name")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {params.row.paidService.map(
                    ({ paidServiceId, serviceName }: any) => (
                      <tr key={paidServiceId}>
                        <td className="border border-slate-300 p-2">
                          {paidServiceId}
                        </td>
                        <td className="border border-slate-300 p-2">
                          {serviceName}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <div className="line-clamp-1 overflow-hidden">
              {params.row.paidService
                .map((item: any) => item.serviceName)
                .join(", ")}
            </div>
          </div>
        );
      },
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      sortable: false,
      flex: 2,
      minWidth: 170,
    },
    {
      field: "amount",
      headerName: "Amount in USD ($)",
      sortable: false,
      type: "string",
      flex: 2,
      minWidth: 160,
    },
    {
      field: "amountOther",
      headerName: "Amount in other currency",
      sortable: false,
      type: "string",
      width: 200,
      renderCell: ({ row }) => {
        const priceOther = row.currency
          ? row.amountOtherCurrency + " " + row.currency
          : "";
        return <div>{priceOther}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created on",
      sortable: false,
      type: "string",
      flex: 2,
      minWidth: 96,
      renderCell: (params) => {
        return generateFormatDate(new Date(params.row.createdAt));
      },
    },
  ];
  const translation = useTranslation();
  return (
    <div className={"w-full grow flex flex-col p-3 "}>
      <div
        className={
          "flex flex-col grow  bg-white rounded justify-start items-center py-6 px-4 sm:px-8 border-[1px] border-[#e5e7eb]"
        }
      >
        <p className={"text-h4 w-full text-start mb-6"}>
          {translation.t("Order Payment Management")}
        </p>
        <div className={"w-full grow"} key={new Date().getTime()}>
          <Table
            paginationMode="server"
            rows={dataTable}
            columns={orderPaymentColumns}
            sx={{
              height: "calc(100vh - 210px)",
            }}
            pageSizeOptions={[25]}
            getRowId={uuidv4}
            rowCount={data?.totalElements || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => {
              setPaginationModel(model);
              setTimeout(refetch);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderPaymentContent;
