import clsx from "clsx";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompanyInformation,
  Document,
  MailingAddress,
  OwnerInformation,
} from "../../api/types";
import {
  DialogFailureFullscreen,
  DialogSuccessFullscreen,
} from "../../components/DialogFormStatusFullscreen";
import {
  useApiGetMyCompanyDetail,
  useApiUpdateMyCompanyDetail,
} from "../../hooks-api/useMyCompany";
import {
  validateCompanyInfo,
  validateMailingAddress,
  validateOwnersInfo,
} from "../../services-business/myCompany";
import { CompanyInformationTab } from "./CompanyInformationTab";
import { DocumentTab } from "./DocumentTab";
import { MailingAddressTab } from "./MailingAddressTab";
import { OwnerInformationTab } from "./OwnerInformationTab";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { NotificationUnreadLinesIcon } from "src/components/icons";
import { RoutePaths } from "src/constants/routerPaths";
import { Link } from "react-router-dom";
import ButtonCs from "src/components/ButtonCs";

const TABS = [
  "Company Information",
  "Shareholder",
  "Owner information",
  "Responsible Party",
  "Director",
  "Registered office address",
  // "Document",
] as const;

export function MyCompanyDetailPage() {
  const { t } = useTranslation();
  const { user, triggerSupport } = useContext(AuthContext);
  const { data, status, refetch } = useApiGetMyCompanyDetail();
  const [activeTab, setActiveTab] = useState<(typeof TABS)[number]>(TABS[0]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [error, setError] = useState<string | false>(false);
  const [companyInfo, setCompanyInfo] = useState<Partial<CompanyInformation>>(
    {}
  );
  const [owners, setOwners] = useState<Partial<OwnerInformation>[]>([]);
  const [responseParty, setResponseParty] = useState<
    Partial<OwnerInformation>[]
  >([]);
  const [mailingAddress, setMailingAddress] = useState<Partial<MailingAddress>>(
    {}
  );
  const [documents, setDocuments] = useState<Document[]>([]);

  const { mutateAsync: saveMyCompany, status: savingCompany } =
    useApiUpdateMyCompanyDetail({
      onError: (error) => setError(String(error)),
    });

  const isUs = user?.llcInNation === "United States";

  const tabList = useMemo(() => {
    const result = TABS.filter((tab) => {
      if (isUs) return !["Shareholder", "Director"].includes(tab);
      else return !["Owner information", "Responsible Party"].includes(tab);
    });
    return result;
  }, []);

  useEffect(() => {
    if (status === "success") {
      setCompanyInfo(data.companyInfo);
      setOwners(data.owners);
      setResponseParty(data.director);
      setMailingAddress(data.mailingAddress);
      setDocuments(data.documents);
    }
  }, [status, data]);

  const handleCheckDisabled = () => {
    const currentIndex = tabList.indexOf(activeTab);
    switch (currentIndex) {
      case 0:
        return validateCompanyInfo(companyInfo, isUs);
      case 1:
        return validateOwnersInfo(owners);
      case 2:
        return validateOwnersInfo(responseParty, true);
      default:
        return true;
    }
  };

  const handleSave = async () => {
    try {
      setError(false);
      await saveMyCompany({
        companyDescription: companyInfo.description ?? "",
        companyName: companyInfo.companyName ?? "",
        companyName2: companyInfo.companyName2,
        companyName3: companyInfo.companyName3,
        capital: companyInfo.capital,
        currency: companyInfo.currency,
        entityEnding: companyInfo.entityEnding ?? "",
        formationDate: companyInfo.formationDate,
        companyNumber: companyInfo.companyNumber ?? "",
        appliedStates: companyInfo.appliedStates ?? "",
        website: companyInfo.website ?? "",
        llcEin: companyInfo.llcEin ?? "",
        llcIdNumber: companyInfo.llcIdNumber ?? "",
        industry: companyInfo.industry ?? "",
        industry2: companyInfo.industry2 ?? "",
        incorporationDate: companyInfo.incorporationDate ?? "",
        financialYearEnd: companyInfo.financialYearEnd ?? "",
        mailingAddress: mailingAddress.address,
        mailingCity: mailingAddress.city,
        mailingCountry: mailingAddress.country,
        mailingState: mailingAddress.state ?? "",
        mailingZipCode: mailingAddress.zipCode,
        isUsingDrmsAddress: Boolean(mailingAddress.isUsingDrmsAddress),
        owner: owners.map((item) => ({
          id: item.id ?? 1,
          nationality: item.nationality,
          passportImagesIdCard: item.passportImagesIdCard,
          shareholderExpiryDate: item.shareholderExpiryDate,
          zipCode: item.zipCode,
          companyName: item.companyName ?? "",
          dateOfBirth: item.dateOfBirth ?? "",
          firstName: item.firstName ?? "",
          lastName: item.lastName ?? "",
          ownerShip: item?.ownership?.toString(),
          contactNumber: item.contactNumber,
          idNumber: item?.idNumber,
          email: item?.email,
          businessNumber: item?.businessNumber,
          authorizedPerson: item.authorizedPerson,
          registeredAddress: item?.registeredAddress,
          document: item.document,
          ownerType: item.type === "Company" ? 1 : 2,
        })),
        director: responseParty.map((item) => ({
          id: item.id ?? 1,
          nationality: item.nationality,
          passportImagesIdCard: item.passportImagesIdCard,
          shareholderExpiryDate: item.shareholderExpiryDate,
          zipCode: item.zipCode,
          companyName: item.companyName ?? "",
          firstName: item.firstName ?? "",
          lastName: item.lastName ?? "",
          dateOfBirth: item.dateOfBirth ?? "",
          ownerShip: item?.ownership?.toString(),
          contactNumber: item.contactNumber,
          idNumber: item?.idNumber,
          email: item?.email,
          businessNumber: item?.businessNumber,
          authorizedPerson: item.authorizedPerson,
          registeredAddress: item?.registeredAddress,
          document: item.document,
          ownerType: 2,
        })),
        document: documents.map((item) => ({ document: item.name })),
      });
      setShowSuccessDialog(true);
      refetch();
    } catch (error) {
      setError(error as string);
    }
  };

  return (
    <>
      <div className="flex w-full flex-col border border-l border-stroke bg-white">
        <div className="p-6 grow overflow-y-auto md:p-10">
          <h2 className="relative mb-12 text-lg font-semibold after:absolute after:-bottom-1 after:left-0 after:h-0.5 after:w-16 after:bg-primary">
            {t(`Company Detail - ${user?.llcInNation}`)}
          </h2>

          {!!data?.disableFormEdit && (
            <div className="mb-10 flex flex-row items-center rounded-lg bg-[#E5F9F4] p-2 md:h-[58px] w-full">
              <NotificationUnreadLinesIcon
                className={"h-5 w-5 shrink-0 text-black"}
              />
              <p className="pl-4 w-full">
                {t("Please contact our customer service")}
                <Link
                  to={RoutePaths.support}
                  className={"pl-2 font-bold underline inline-block"}
                  onClick={() =>
                    triggerSupport(
                      "I would like to change information on my Corporation."
                    )
                  }
                >
                  <p>{t("Customer service")}</p>
                </Link>
              </p>
            </div>
          )}

          <div className="relative mb-12 h-14 overflow-x-auto overflow-y-hidden rounded-lg border border-solid border-surface py-1">
            <div className="absolute flex min-w-full whitespace-nowrap px-1">
              {tabList.map((tab) => (
                <div
                  key={tab}
                  className={clsx(
                    "line-clamp-1 inline-flex h-12 w-max min-w-52 grow items-center justify-center rounded-lg font-bold transition-all",
                    activeTab === tab
                      ? "bg-primary_25 text-black"
                      : "text-surface"
                  )}
                >
                  {t(tab)}
                </div>
              ))}
            </div>
          </div>

          <div
            className={`overflow-auto rounded-lg border border-solid border-surface p-6 ${
              !data?.disableFormEdit ? "h-[55vh]" : "h-[45vh]"
            }`}
          >
            {activeTab === "Company Information" && (
              <CompanyInformationTab
                readonly={!!data?.disableFormEdit}
                companyInfo={companyInfo}
                onChange={setCompanyInfo}
              />
            )}
            {["Owner information", "Shareholder"].includes(activeTab) && (
              <OwnerInformationTab
                readonly={!!data?.disableFormEdit}
                owners={owners}
                onChange={setOwners}
                isUS={isUs}
                shareData={responseParty}
              />
            )}
            {["Responsible Party", "Director"].includes(activeTab) && (
              <OwnerInformationTab
                readonly={!!data?.disableFormEdit}
                owners={responseParty}
                shareData={owners}
                onChange={setResponseParty}
                isUS={isUs}
                isDirector={true}
              />
            )}
            {activeTab === "Registered office address" && (
              <MailingAddressTab
                readonly={!!data?.disableFormEdit}
                mailingAddress={mailingAddress}
                onChange={setMailingAddress}
              />
            )}
            {/* {activeTab === "Document" && (
              <DocumentTab
                readonly={false}
                documents={documents}
                onChange={setDocuments}
              />
            )} */}
          </div>
        </div>

        <div className="flex h-20 shrink-0 items-center justify-end gap-2 border-t border-solid border-stroke px-8">
          <ButtonCs
            className="border border-surface text-primary bg-transparent"
            onClick={() => {
              const currentIndex = tabList.indexOf(activeTab);
              setActiveTab(tabList[currentIndex - 1]);
            }}
            disabled={activeTab === "Company Information"}
          >
            {t("Previous")}
          </ButtonCs>
          {activeTab !== "Registered office address" && (
            <ButtonCs
              onClick={() => {
                const currentIndex = tabList.indexOf(activeTab);
                setActiveTab(tabList[currentIndex + 1]);
              }}
              disabled={handleCheckDisabled()}
            >
              {t("Next")}
            </ButtonCs>
          )}
          {activeTab === "Registered office address" && (
            <ButtonCs
              onClick={() => {
                handleSave();
              }}
              disabled={
                !!data?.disableFormEdit ||
                validateMailingAddress(mailingAddress)
              }
            >
              {t("Submit")}
            </ButtonCs>
          )}
        </div>
      </div>

      {showSuccessDialog && (
        <DialogSuccessFullscreen
          onClose={() => setShowSuccessDialog(false)}
          title="Company information updated"
          actionElement={
            <button
              onClick={() => setShowSuccessDialog(false)}
              className="flex h-[52px] w-full items-center justify-center gap-2 rounded-lg bg-primary font-semibold text-white"
            >
              Close
            </button>
          }
        />
      )}

      {error && (
        <DialogFailureFullscreen
          title={error}
          onClose={() => setError(false)}
          actionElement={
            <button
              onClick={() => setError(false)}
              className="flex h-[52px] w-full items-center justify-center gap-2 rounded-lg bg-primary font-semibold text-white"
            >
              Close
            </button>
          }
        />
      )}
    </>
  );
}
