import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  DialogFailureFullscreen,
  DialogSuccessFullscreen,
} from "src/components/DialogFormStatusFullscreen";
import {
  IconSpinner,
} from "src/components/icons";
import { RoutePaths } from "src/constants/routerPaths";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { useVerifyKYC } from "src/hooks-api/useVerifyKYC";
import TakeOrUploadPhoto from "src/pages/KYCUploadContent/components/TakeOrUploadPhoto";
import { callApiGetUserProfile } from "../../api/account";
import UploadVideoPassPort from "./components/TakeOrUploadPhoto/UploadVideoPassPort";

export default function KYCUploadContentManual() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { user, saveAuthUser } = useContext(AuthContext);
  const [passport, setPassport] = useState<File | null>(null);
  const [pictureHoldPassport, setPictureHoldPassport] =
    useState<File[] | null>(null);

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const {
    mutateAsync: uploadKYC,
    isPending,
    error,
  } = useVerifyKYC({
    onError: () => setShowErrorDialog(true),
    onSuccess: () => setShowSuccessDialog(true),
  });

  const handleClickSend = async () => {
    if (!passport || !pictureHoldPassport) return;
    try {
      await uploadKYC({ passport: passport, pictures: pictureHoldPassport });
      const user = await callApiGetUserProfile();
      saveAuthUser(user);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  function handleCloseSuccessKyc() {
    setShowSuccessDialog(false);
    navigate(RoutePaths.myAccount);
  }

  const isDisableSend =
    (!passport && user?.llcInNation !== "United States") || !pictureHoldPassport;

  return (
    <>
      <div
        className={
          "w-full grow flex flex-col p-3 bg-white border border-solid border-t border-l"
        }
      >
        <div
          className={
            "flex flex-col grow overflow-x-hidden overflow-y-scroll bg-white rounded justify-start items-center py-6 px-4"
          }
        >
          <div className={"w-full max-w-[800px] flex flex-col gap-y-6"}>
            <div className={"flex flex-row justify-between"}>
              <div className={"space-y-1"}>
                <p className={"font-bold"}>{translation.t("KYC")}</p>
                <div className={"h-[2px] w-[70px] bg-primary"}></div>
              </div>
            </div>
            <p className={"font-bold"}>
              1. {translation.t("Upload your Passport")}
            </p>
            <TakeOrUploadPhoto onUpload={setPictureHoldPassport} />
            <p className={"font-bold"}>
              2. {translation.t("Upload your video holding the passport")}
            </p>
            <UploadVideoPassPort
              onUpload={setPassport}
              isUS={user?.llcInNation === "United States"}
            />
            <div className={"flex flex-row justify-end gap-4"}>
              <Link
                to={RoutePaths.myAccount}
                className={
                  "flex justify-center items-center gap-2 font-semibold rounded-lg py-4 px-6 border text-gray-600"
                }
              >
                <span className={"font-bold"}>{translation.t("Cancel")}</span>
              </Link>
              <button
                disabled={isDisableSend}
                onClick={handleClickSend}
                className={
                  "py-4 px-6 flex flex-row justify-center items-center gap-2 text-white font-semibold rounded-lg " +
                  (isDisableSend ? " bg-primary_25" : " bg-primary")
                }
              >
                <span className={"font-bold"}>{translation.t("Send")}</span>
                {isPending && <IconSpinner />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showErrorDialog && (
        <DialogFailureFullscreen
          onClose={() => setShowErrorDialog(false)}
          title="Upload Failed"
          subTitle={error?.message}
        />
      )}
      {showSuccessDialog && (
        <DialogSuccessFullscreen
          onClose={handleCloseSuccessKyc}
          title="Submitted Successfully!"
          subTitle="Please wait for reviewing"
          actionElement={
            <button
              className="bg-primary w-full rounded-lg py-3 text-white"
              onClick={handleCloseSuccessKyc}
            >
              {translation.t("Close")}
            </button>
          }
        />
      )}
    </>
  );
}
