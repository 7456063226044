import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGE from "src/assets/images";
import { NewCom, ExistCom, AltArrowRight } from "src/components/icons";
import { RoutePaths } from "src/constants/routerPaths";

const DirectionalPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex p-16 gap-8">
      <div>
        <img
          className="w-[60vw] h-[85vh]"
          src={IMAGE.directionBgr}
          alt="logo_full"
        />
      </div>
      <div className="flex flex-col gap-32">
        <div className="flex flex-col gap-8">
          <img
            className="w-[150px] cursor-pointer"
            src={IMAGE.logoFull}
            alt="logo_full"
          />
          <p className="font-bold text-4xl">{t("Launch and Manage")}</p>
          <p className="text-2xl">{t("Form a new company")}</p>
        </div>
        <div className="flex flex-col gap-8">
          <Link to={RoutePaths.register}>
            <div className="flex justify-between items-center gap-6 [box-shadow:0px_0px_19.1px_0px_#0000001A] rounded-lg p-4 cursor-pointer">
              <div className="flex items-center gap-8">
                <NewCom className={"shrink-0 text-black"} />
                <p className="font-bold">{t("SET_UP_NEW_COMPANY")}</p>
              </div>
              <AltArrowRight className={"shrink-0 text-black"} />
            </div>
          </Link>
          <Link to={RoutePaths.login}>
            <div className="flex justify-between items-center gap-6 [box-shadow:0px_0px_19.1px_0px_#0000001A] rounded-lg p-4 cursor-pointer">
              <div className="flex items-center gap-8">
                <ExistCom className={"shrink-0 text-black "} />
                <p className="font-bold">{t("MANAGE_MY_EXISTING_COMPANY")}</p>
              </div>
              <AltArrowRight className={"shrink-0 text-black"} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DirectionalPage;
