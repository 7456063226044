import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { OwnerInformation } from "../../api/types";
import { FormFieldMultipleUpload } from "../../components/FormFieldMultipleUpload";
import { FormFieldNumber } from "../../components/FormFieldNumber";
import { FormFieldText } from "../../components/FormFieldText";
import { IconEssential, IconXCircle } from "../../components/icons";
import { useValidateCaller } from "../../hooks-ui/useValidateCaller";
import { cn } from "../../utils/cn.util";
import { useMemo } from "react";
import { FormFieldSelect } from "src/components/FormFieldSelect";
import FormDateTime from "src/components/FormDateTime";
import dayjs from "dayjs";
import { APPLIED_NATIONS } from "src/constants/SelectionOptions";

type OwnerInformationTabProps = {
  readonly: boolean;
  owners?: Partial<OwnerInformation>[];
  shareData?: Partial<OwnerInformation>[];
  onChange?: (owners: Partial<OwnerInformation>[]) => void;
  isUS?: boolean;
  isDirector?: boolean;
};

export function OwnerInformationTab({
  readonly,
  owners = [],
  shareData = [],
  onChange,
  isUS,
  isDirector,
}: OwnerInformationTabProps) {
  const { t } = useTranslation();
  const totalShare = owners?.reduce(
    (acc, cur) => acc + (cur.ownership ?? 0),
    0
  );

  const handleFormChange = <K extends keyof OwnerInformation>(
    id: number = 1,
    key: K,
    value: OwnerInformation[K]
  ) => {
    const newOwnersInfo = [...owners];
    const target = newOwnersInfo.find((data) => data.id === id);
    if (isDirector && key === "id") {
      if (
        target?.id !== value &&
        newOwnersInfo.some((info) => info.id === value)
      )
        return;
      const found = shareData.find(
        (data) => data.type === "Individual" && data.id === value
      );
      if (found && target) handleTransformData(target, found);
      if (value === -1 && target) handleTransformData(target);
    }
    if (target) target[key] = value;

    // update director from owner
    if (!isDirector) {
      const found = shareData.find(
        (data) => data.type === "Individual" && data?.id === target?.id
      );
      if (found && target?.type === "Individual") found[key] = value;
      if (found && target?.type === "Company") handleTransformData(found);
    }
    onChange?.(newOwnersInfo);
  };

  const handleTransformData = (
    oldData: Partial<OwnerInformation>,
    newData?: Partial<OwnerInformation>
  ) => {
    if (!oldData) return;
    oldData.id = newData?.id ?? 0;
    oldData.nationality = newData?.nationality;
    oldData.passportImagesIdCard = newData?.passportImagesIdCard;
    oldData.shareholderExpiryDate = newData?.shareholderExpiryDate;
    oldData.zipCode = newData?.zipCode;
    oldData.companyName = newData?.companyName;
    oldData.firstName = newData?.firstName;
    oldData.lastName = newData?.lastName;
    oldData.ownership = newData?.ownership;
    oldData.contactNumber = newData?.contactNumber;
    oldData.idNumber = newData?.idNumber;
    oldData.email = newData?.email;
    oldData.businessNumber = newData?.businessNumber;
    oldData.authorizedPerson = newData?.authorizedPerson;
    oldData.registeredAddress = newData?.registeredAddress;
    oldData.dateOfBirth = newData?.dateOfBirth;
    oldData.document = newData?.document;
    oldData.type = newData?.type;
  };

  const { validateCaller } = useValidateCaller();

  const handleAddOwner = () => {
    onChange?.([
      ...owners,
      {
        id: new Date().getTime(),
        companyName: "",
        ownership: 0,
        document: [],
        type: "Individual",
      },
    ]);
  };

  const handleDeleteItem = (id: number) => {
    onChange?.(owners?.filter((o) => o.id !== id));
    if (!isDirector) {
      shareData.forEach((item) => {
        if (item.id !== id) return;
        handleTransformData(item);
      });
    }
  };

  const listShareHolderName = useMemo(() => {
    return shareData
      .filter((data) => data.type === "Individual")
      .map((data) => ({
        value: data?.id,
        label: data?.firstName + " " + data?.lastName ?? "",
      }))
      .concat([{ value: -1, label: "--.--" }]);
  }, [shareData, owners]);

  const renderCompanyMode = (owner: Partial<OwnerInformation>) => {
    return (
      <>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={readonly}
            label={t("Company Name")}
            isRequired
            validateCaller={validateCaller}
            id="companyName"
            value={owner.companyName}
            onChange={(value) =>
              handleFormChange(owner.id, "companyName", value)
            }
          />
        </div>

        <div className="flex xl:gap-2">
          <FormFieldText
            isFixedValue={readonly}
            label={t("Business Number")}
            isRequired
            validateCaller={validateCaller}
            id="businessNumber"
            value={owner.businessNumber}
            onChange={(value) =>
              handleFormChange(owner.id, "businessNumber", value)
            }
          />
          <FormFieldNumber
            isFixedValue={readonly}
            label={t("Ownership (%)")}
            isRequired
            validateCaller={validateCaller}
            id="ownership"
            value={owner.ownership}
            onChange={(value) => handleFormChange(owner.id, "ownership", value)}
          />
        </div>
        <div className="col-span-1">
          <FormFieldSelect
            isFixedValue={readonly}
            optionInfos={APPLIED_NATIONS}
            label={t("Country Of Incorporation")}
            isRequired
            className="w-full"
            id="nationality"
            value={owner.nationality}
            validateCaller={validateCaller}
            onChange={(value) =>
              handleFormChange(owner.id, "nationality", value)
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={readonly}
            label={t("Authorized Person")}
            isRequired
            validateCaller={validateCaller}
            id="authorizedPerson"
            value={owner.authorizedPerson}
            onChange={(value) =>
              handleFormChange(owner.id, "authorizedPerson", value)
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={readonly}
            label={t("Contact Email")}
            validateCaller={{}}
            id="email"
            value={owner.email}
            onChange={(value) => handleFormChange(owner.id, "email", value)}
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={readonly}
            label={t("Residential Address")}
            isRequired
            validateCaller={validateCaller}
            id="registeredAddress"
            value={owner.registeredAddress}
            onChange={(value) =>
              handleFormChange(owner.id, "registeredAddress", value)
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={readonly}
            label={t("ZIP Code/Postal")}
            isRequired
            validateCaller={validateCaller}
            id="zipCode"
            value={owner.zipCode}
            onChange={(value) => handleFormChange(owner.id, "zipCode", value)}
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={readonly}
            label={t("Contact Number")}
            validateCaller={validateCaller}
            id="contactNumber"
            value={owner.contactNumber}
            onChange={(value) =>
              handleFormChange(owner.id, "contactNumber", value)
            }
          />
        </div>
        <div className="col-span-1 xl:col-span-2">
          <FormFieldMultipleUpload
            isFixedValue={readonly}
            label={t("Cert. Of Incorporation")}
            isRequired
            validateCaller={validateCaller}
            id={`document-${owner.id}`}
            onChange={(value) =>
              handleFormChange(
                owner.id,
                "document",
                value?.map((f) => f.id)
              )
            }
            value={owner?.document?.map((doc) => ({
              id: doc,
              name: doc,
              url: doc,
            }))}
          />
        </div>
        <div className="col-span-1 xl:col-span-2">
          <FormFieldMultipleUpload
            isFixedValue={readonly}
            label={t("M&A")}
            isRequired
            validateCaller={validateCaller}
            id={`documentttt-${owner.id}`}
            onChange={(value) =>
              handleFormChange(
                owner.id,
                "passportImagesIdCard",
                value?.map((f) => f.id)
              )
            }
            value={owner?.passportImagesIdCard?.map((doc) => ({
              id: doc,
              name: doc,
              url: doc,
            }))}
          />
        </div>
      </>
    );
  };

  const renderIndividualMode = (owner: Partial<OwnerInformation>) => {
    const isDisabled =
      readonly ||
      (isDirector &&
        shareData.some(
          (data) => data.id === owner.id && data.type === "Individual"
        ));
    return (
      <>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            label={t("First Name")}
            isRequired
            validateCaller={validateCaller}
            id="firstName"
            value={owner.firstName}
            onChange={(value) => handleFormChange(owner.id, "firstName", value)}
          />
        </div>

        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            label={t("Last Name")}
            isRequired
            validateCaller={validateCaller}
            id="lastName"
            value={owner.lastName}
            onChange={(value) => handleFormChange(owner.id, "lastName", value)}
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            label={t("Passport Number")}
            isRequired
            validateCaller={validateCaller}
            id="idNumber"
            value={owner.idNumber}
            onChange={(value) => handleFormChange(owner.id, "idNumber", value)}
          />
        </div>
        <div className="col-span-1">
          <FormDateTime
            label={t("Passport Expiry Date")}
            value={
              owner?.shareholderExpiryDate
                ? dayjs(owner?.shareholderExpiryDate)
                : null
            }
            readOnly={isDisabled}
            isRequired
            onChange={(value) =>
              handleFormChange(
                owner.id,
                "shareholderExpiryDate",
                value?.format() ?? ""
              )
            }
          />
        </div>
        <div className="col-span-1">
          <FormDateTime
            label={t("Date of Birth")}
            isRequired
            value={owner?.dateOfBirth ? dayjs(owner?.dateOfBirth) : null}
            readOnly={isDisabled}
            onChange={(value) =>
              handleFormChange(owner.id, "dateOfBirth", value?.format() ?? "")
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldNumber
            isFixedValue={isDisabled}
            label={t("Ownership (%)")}
            isRequired={!isDirector}
            validateCaller={validateCaller}
            id="ownership"
            value={owner.ownership}
            onChange={(value) => handleFormChange(owner.id, "ownership", value)}
          />
        </div>
        <div className="col-span-1">
          <FormFieldSelect
            isFixedValue={isDisabled}
            optionInfos={APPLIED_NATIONS}
            label={t("Nationality")}
            isRequired
            className="w-full"
            id="nationality"
            value={owner.nationality}
            validateCaller={validateCaller}
            onChange={(value) =>
              handleFormChange(owner.id, "nationality", value)
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            label={t("Contact Email")}
            isRequired
            validateCaller={validateCaller}
            id="email"
            value={owner.email}
            onChange={(value) => handleFormChange(owner.id, "email", value)}
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            isRequired
            label={t("Contact Number")}
            validateCaller={validateCaller}
            id="contactNumber"
            value={owner.contactNumber}
            onChange={(value) =>
              handleFormChange(owner.id, "contactNumber", value)
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            label={t("Residential Address")}
            isRequired
            validateCaller={validateCaller}
            id="registeredAddress"
            value={owner.registeredAddress}
            onChange={(value) =>
              handleFormChange(owner.id, "registeredAddress", value)
            }
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            isFixedValue={isDisabled}
            label={t("ZIP Code/Postal")}
            isRequired
            validateCaller={validateCaller}
            id="zipCode"
            value={owner.zipCode}
            onChange={(value) => handleFormChange(owner.id, "zipCode", value)}
          />
        </div>
        <div className="col-span-1 xl:col-span-2">
          <FormFieldMultipleUpload
            isFixedValue={isDisabled}
            label={t("Bank Statement/Utility Bill (include video holding the documents & Passport)")}
            isRequired
            validateCaller={validateCaller}
            id={`document-${owner.id}`}
            onChange={(value) =>
              handleFormChange(
                owner.id,
                "document",
                value?.map((f) => f.id)
              )
            }
            value={owner?.document?.map((doc) => ({
              id: doc,
              name: doc,
              url: doc,
            }))}
          />
        </div>
        <div className="col-span-1 xl:col-span-2">
          <FormFieldMultipleUpload
            isFixedValue={isDisabled}
            label={t("Passport Images/ID Card")}
            isRequired
            validateCaller={validateCaller}
            id={`documentttt-${owner.id}`}
            onChange={(value) =>
              handleFormChange(
                owner.id,
                "passportImagesIdCard",
                value?.map((f) => f.id)
              )
            }
            value={owner?.passportImagesIdCard?.map((doc) => ({
              id: doc,
              name: doc,
              url: doc,
            }))}
          />
        </div>
      </>
    );
  };

  const handleRenderOwnerHeader = (owner: Partial<OwnerInformation>) => {
    return (
      <>
        <label
          htmlFor={`individual-${owner.id}`}
          className="flex gap-1 items-center cursor-pointer"
        >
          <input
            type="checkbox"
            name="type"
            id={`individual-${owner.id}`}
            className="accent-primary w-5 h-5 border-surface rounded-lg"
            checked={owner.type === "Individual"}
            onChange={(event) => {
              if (owner.type === "Individual") return;
              const isChecked = event.currentTarget.checked;
              if (!isChecked) return;
              handleFormChange(owner.id, "type", "Individual");
            }}
          />
          <span>{t("Individual")}</span>
        </label>
        <label
          htmlFor={`company-${owner.id}`}
          className="flex gap-1 items-center cursor-pointer"
        >
          <input
            type="checkbox"
            name="type"
            id={`company-${owner.id}`}
            className="accent-primary w-5 h-5 border-surface rounded-lg"
            checked={owner.type === "Company"}
            onChange={(event) => {
              if (owner.type === "Company") return;
              const isChecked = event.currentTarget.checked;
              if (!isChecked) return;
              handleFormChange(owner.id, "type", "Company");
            }}
          />
          <span>{t("Company")}</span>
        </label>
      </>
    );
  };

  const handleRenderHeaderDirector = (owner: Partial<OwnerInformation>) => {
    return (
      <div className="flex flex-col items-start gap-2 xl:flex-row xl:items-center">
        <span className="font-bold">
          {isUS ? t("Owner Name") : t("Shareholder Name")}
        </span>
        <div className="w-[280px]">
          <FormFieldSelect
            isFixedValue={readonly}
            optionInfos={listShareHolderName}
            isRequired
            className="w-full"
            id="nationality"
            value={owner.id ?? -1}
            directorList={owners}
            validateCaller={validateCaller}
            onChange={(value) => handleFormChange(owner.id, "id", value)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {totalShare > 100 && (
        <div className="text-danger mb-2">
          {t("Total ownership must be less than or equal 100.")}
        </div>
      )}
      {owners?.map((owner, idx) => (
        <div key={owner.id} className="">
          <div className="grid grid-cols-1 xl:flex xl:justify-between">
            <div className="underline text-lg font-bold mb-6">
              {`${
                isDirector
                  ? isUS
                    ? t("Responsible Party")
                    : t("Director")
                  : isUS
                  ? t("Owner")
                  : t("Shareholder")
              } ${idx + 1}`}
            </div>

            <div
              className={clsx("flex gap-3 mb-4", {
                "pointer-events-none ": readonly,
              })}
            >
              {!isDirector
                ? handleRenderOwnerHeader(owner)
                : handleRenderHeaderDirector(owner)}
              {owners.length > 1 && (
                <button
                  className="cursor-pointer mt-auto mb-2 xl:block hidden"
                  onClick={() => {
                    owner.id && handleDeleteItem(owner.id);
                  }}
                >
                  <IconXCircle />
                </button>
              )}
            </div>
          </div>

          <div
            className={cn(
              "grid grid-cols-1 xl:grid-cols-4 gap-x-4 gap-y-6 justify-start mb-6"
            )}
          >
            {owner.type === "Company" && !isDirector
              ? renderCompanyMode(owner)
              : renderIndividualMode(owner)}
          </div>
        </div>
      ))}

      {!readonly && (
        <button
          className={cn(
            "rounded-lg bg-primary h-13 px-6 text-white font-semibold flex items-center gap-3 p-3",
            {
              "bg-disable cursor-not-allowed": totalShare >= 100,
            }
          )}
          onClick={handleAddOwner}
          disabled={totalShare >= 100}
        >
          <IconEssential /> {isDirector ? t("Add More") : t("Add Owner")}
        </button>
      )}
    </div>
  );
}
