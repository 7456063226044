/* eslint-disable no-undef */
import { useContext, useMemo } from "react";
import faceIO from "@faceio/fiojs";
import { useTranslation } from "react-i18next";
import { AuthContext } from "src/contexts/AuthContextProvider";
import { FaceRegisterIcon, FaceVerifiedIcon } from "src/components/icons";
import { FaceLoginIcon } from "src/components/icons";
import {
  callApiDeleteFaceID,
  callApiGetUserProfile,
  callApiLoginByFaceID,
  callApiUpdateFaceID,
} from "src/api/account";
import { saveAuthInfo } from "src/services-business/api/authentication";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants/routerPaths";

export const FACEIO_APP_API_KEY = "fioa14e9";
interface Props {
  isRegister?: boolean;
}
const Faceio = (props: Props) => {
  const { isRegister } = props;
  const translation = useTranslation();
  const { user, saveAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const faceioInstance = useMemo(() => new faceIO(FACEIO_APP_API_KEY), []);
  const faceID = useMemo(() => user?.faceId, [user]);

  const handleUpdateFaceID = async () => {
    const sidaBar = document.getElementById("sidebarId");
    if (sidaBar) sidaBar.style.zIndex = "0";
    if (!faceioInstance) return;
    try {
      const response = await faceioInstance.enroll({
        locale: "en",
        permissionTimeout: 5,
        termsTimeout: 5,
        idleTimeout: 5,
        enrollIntroTimeout: 5,
        payload: {
          email: "example@gmail.com",
          pin: "12345",
        },
      });
      await callApiUpdateFaceID(response.facialId);
      const newUser = await callApiGetUserProfile();
      saveAuthUser(newUser);
    } catch (error) {
      handlefaceIoError(error);
    } finally {
      faceioInstance.restartSession({});
      if (sidaBar) sidaBar.style.zIndex = "50";
    }
  };

  const faceSignIn = async () => {
    if (!faceioInstance) return;
    try {
      const response = await faceioInstance.authenticate({
        locale: "en",
      });
      const result = await callApiLoginByFaceID(response.facialId);
      saveAuthInfo(result);
      const newUser = await callApiGetUserProfile();
      saveAuthUser(newUser);
      setTimeout(() => navigate(RoutePaths.home), 2000);
    } catch (error) {
      handlefaceIoError(error);
    } finally {
      faceioInstance.restartSession({});
    }
  };

  const handleDeleteFaceID = async () => {
    try {
      await callApiDeleteFaceID();
      const newUser = await callApiGetUserProfile();
      saveAuthUser(newUser);
    } catch {
    } finally {
      faceioInstance.restartSession({});
    }
  };

  const handlefaceIoError = (error: any) => {
    switch (error) {
      case error.PERMISSION_REFUSED:
        return translation.t("PERMISSION_REFUSED");
      case error.NO_FACES_DETECTED:
        return translation.t("NO_FACES_DETECTED");
      case error.UNRECOGNIZED_FACE:
        return translation.t("UNRECOGNIZED_FACE");
      case error.MANY_FACES:
        return translation.t("MANY_FACES");
      case error.FACE_DUPLICATION:
        return translation.t("FACE_DUPLICATION");
      case error.PAD_ATTACK:
        return translation.t("PAD_ATTACK");
      case error.FACE_MISMATCH:
        return translation.t("FACE_MISMATCH");
      case error.WRONG_PIN_CODE:
        return translation.t("WRONG_PIN_CODE");
      case error.PROCESSING_ERR:
        return translation.t("PROCESSING_ERR");
      case error.UNAUTHORIZED:
        return translation.t("UNAUTHORIZED");
      case error.TERMS_NOT_ACCEPTED:
        return translation.t("TERMS_NOT_ACCEPTED");
      case error.UI_NOT_READY:
        return translation.t("UI_NOT_READY");
      case error.SESSION_EXPIRED:
        return translation.t("SESSION_EXPIRED");
      case error.TIMEOUT:
        return translation.t("TIMEOUT");
      case error.TOO_MANY_REQUESTS:
        return translation.t("TOO_MANY_REQUESTS");
      case error.EMPTY_ORIGIN:
        return translation.t("EMPTY_ORIGIN");
      case error.FORBIDDDEN_ORIGIN:
        return translation.t("FORBIDDDEN_ORIGIN");
      case error.FORBIDDDEN_COUNTRY:
        return translation.t("FORBIDDDEN_COUNTRY");
      case error.SESSION_IN_PROGRESS:
        return translation.t("SESSION_IN_PROGRESS");
      case error.NETWORK_IO:
      default:
        return translation.t("NETWORK_IO");
    }
  };

  const renderRegister = () => {
    return (
      <div className="flex flex-col h-full mt-8 justify-between">
        <div className={"space-y-1"}>
          <p className={"font-bold"}>{translation.t("Face Recognition")}</p>
          <div className={"h-[2px] w-[70px] bg-primary"}></div>
        </div>
        {faceID ? (
          <>
            <div className="flex flex-row gap-4 items-center p-2 bg-[#E5F9F4] rounded-lg">
              <FaceVerifiedIcon className={"shrink-0 text-black w-5 h-5"} />
              <p>{translation.t("VERIFIED_FACE")}</p>
            </div>
            <div className={"flex justify-end gap-2"}>
              <button
                className="py-4 px-6 mt-8 flex justify-center items-center gap-2 bg-[#f16c6c] text-white font-semibold rounded-lg w-[112px]"
                onClick={handleDeleteFaceID}
              >
                {translation.t("Delete")}
              </button>
              <button
                className="py-4 px-6 mt-8 flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg w-[112px]"
                onClick={handleUpdateFaceID}
              >
                {translation.t("Update")}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row gap-4 items-center p-2 bg-red-200 rounded-lg">
              <FaceRegisterIcon className={"shrink-0 text-black w-5 h-5"} />
              <p>{translation.t("NOT_VERIFIED_FACE")}</p>
            </div>
            <div className={"flex justify-end"}>
              <button
                className={`py-4 px-6 mt-8 flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg w-[112px] ${
                  user?.kycStatus !== "Approved" ? "opacity-60" : "opacity-100"
                }`}
                onClick={handleUpdateFaceID}
                disabled={user?.kycStatus !== "Approved"}
              >
                {translation.t("Registration")}
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderLogin = () => {
    return (
      <button
        className="h-[52px] flex justify-center items-center gap-2  text-[#094752] border-[#094752] border-2 font-semibold rounded-lg"
        onClick={faceSignIn}
      >
        <FaceLoginIcon className={"shrink-0 text-black w-5 h-5"} />
        {translation.t("LOGIN_FACEIO")}
      </button>
    );
  };

  return isRegister ? renderRegister() : renderLogin();
};

export default Faceio;
