import { useQuery } from "@tanstack/react-query"
import { getOrderPayment } from "src/api/payment"
import { ParamsOrderPayment } from "src/api/types"

export const useApiGetOrderPayment = (params: ParamsOrderPayment) => {
  return useQuery({
    queryKey: [],
    queryFn: () => getOrderPayment(params)
  })
}
