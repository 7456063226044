import { createContext, PropsWithChildren, useState } from "react";
import { RawResultGetUserProfile } from "../api/types";

type AuthUser = RawResultGetUserProfile;

type AuthContextValue = {
  user: AuthUser | null;
  saveAuthUser: (user: AuthUser) => void;
  removeAuthUser: () => void;
  supportMessage: string;
  triggerSupport: (value: string) => void;
};

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  saveAuthUser: () => {},
  removeAuthUser: () => {},
  supportMessage: "",
  triggerSupport: () => {},
});

export function AuthContextProvider(props: PropsWithChildren) {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [supportMessage, setSupportMessage] = useState("");

  function saveAuthUser(authUserData: AuthUser) {
    setUser(authUserData);
  }

  function removeAuthUser() {
    setUser(null);
  }

  function triggerSupport(value: string) {
    setSupportMessage(value);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        saveAuthUser,
        removeAuthUser,
        supportMessage,
        triggerSupport,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
