import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import { DownloadIcon, IconX } from "./icons";
import { getFile } from "../api/upload";
import ButtonCs from "./ButtonCs";
import CantViewFile from "../assets/images/CantViewFile.jpg";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  urlPreview: string;
  fileType?: string;
  onClose: () => void;
  fileId?: string;
  canDownload?: boolean;
}

const MediaPreview = (props: IProps) => {
  const { urlPreview, fileType, onClose, fileId, canDownload = true } = props;
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isError, setIsError] = useState<boolean>(false);
  const { t } = useTranslation();

  const isImgFile = fileType?.toLowerCase().includes("image");
  const isVideoFile = fileType?.toLowerCase().includes("video");

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleDownload = () => {
    if (!fileId) return;
    getFile(fileId);
  };

  const handlePreviewPDF = () => {
    return (
      <div>
        <div
          className={`overflow-auto ${
            !isError ? "h-[80vh] w-[80vw] sm:w-[35vw]" : ""
          }`}
        >
          <Document
            file={urlPreview}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setIsError(true)}
            error={
              <div className="mx-auto w-full text-center">
                <span className="font-bold">{t("No preview available!")}</span>
                <img className="w-[300px] mt-4" src={CantViewFile} />
              </div>
            }
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        {!isError && (
          <div className=" flex flex-col gap-2 justify-center items-center">
            <p>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <div className="flex gap-4">
              <ButtonCs
                type="submit"
                onClick={previousPage}
                disabled={pageNumber <= 1}
              >
                {t("Previous")}
              </ButtonCs>
              <ButtonCs
                type="submit"
                onClick={nextPage}
                disabled={pageNumber === numPages}
              >
                {t("Next")}
              </ButtonCs>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handlePreviewImage = () => (
    <div className="w-[60vw] mx-4 flex justify-center items-center flex-col gap-y-8">
      <img src={urlPreview} />
    </div>
  );

  const handlePreviewVideo = () => (
    <div className="w-[60vw]">
      <video
        className="aspect-video mx-auto object-contain rounded-lg overflow-hidden"
        src={urlPreview}
        controls
      />
    </div>
  );

  return (
    <div className="p-4 flex flex-col items-end gap-4">
      <div className="flex gap-4 items-center">
        {canDownload && (
          <DownloadIcon
            className=" cursor-pointer scale-75"
            onClick={handleDownload}
          />
        )}
        <IconX className=" cursor-pointer" onClick={onClose} />
      </div>
      {isImgFile
        ? handlePreviewImage()
        : isVideoFile
        ? handlePreviewVideo()
        : handlePreviewPDF()}
    </div>
  );
};

export default MediaPreview;
