import { CompanyDetail, EntityEnding, RawCompanyDetail } from "src/api/types";

export function transformGetCompanyDetail(data: RawCompanyDetail): CompanyDetail {
  const companyDetail: CompanyDetail = {
    companyInfo: {
      companyName: data.companyName,
      companyName2: data.companyName2,
      companyName3: data.companyName3,
      capital: data.capital,
      currency: data.currency,
      description: data.companyDescription,
      entityEnding: data.entityEnding as EntityEnding,
      industry: data.industry,
      industry2: data.industry2,
      website: data.website,
      formationDate: data.formationDate ?? '',
      companyNumber: data.companyNumber ?? '',
      appliedStates: data.appliedStates ?? '',
      llcEin: data.llcEin ?? '',
      llcIdNumber: data.llcIdNumber ?? '',
      incorporationDate: data.incorporationDate ?? '',
      financialYearEnd: data.financialYearEnd ?? '',
    },
    owners: data.owner.map(o => ({
      id: o.id,
      companyName: o.companyName ?? "",
      ownership: Number(o.ownerShip),
      type: o.ownerType === 1 ? "Company" : "Individual",
      firstName: o.firstName ?? "",
      lastName: o.lastName ?? "",
      contactNumber: o.contactNumber,
      shareholderExpiryDate: o.shareholderExpiryDate,
      email: o.email ?? "",
      idNumber: o.idNumber ?? '',
      registeredAddress: o.registeredAddress ?? "",
      businessNumber: o.businessNumber ?? "",
      authorizedPerson: o.authorizedPerson ?? "",
      nationality: o.nationality,
      zipCode: o.zipCode ?? "",
      dateOfBirth: o.dateOfBirth ?? "",
      document: o.document.split(","),
      passportImagesIdCard: o.passportImagesIdCard?.split(","),
    })),
    director: data.director.map(o => ({
      id: o.id,
      companyName: o.companyName ?? "",
      ownership: Number(o.ownerShip),
      type: o.ownerType === 1 ? "Company" : "Individual",
      firstName: o.firstName ?? "",
      lastName: o.lastName ?? "",
      contactNumber: o.contactNumber,
      shareholderExpiryDate: o.shareholderExpiryDate,
      email: o.email ?? "",
      nationality: o.nationality,
      dateOfBirth: o.dateOfBirth ?? "",
      idNumber: o.idNumber ?? '',
      registeredAddress: o.registeredAddress ?? "",
      businessNumber: o.businessNumber ?? "",
      authorizedPerson: o.authorizedPerson ?? "",
      zipCode: o.zipCode ?? "",
      document: o.document.split(","),
      passportImagesIdCard: o.passportImagesIdCard?.split(","),
    })),
    mailingAddress: {
      address: data.mailingAddress ?? '',
      city: data.mailingCity ?? '',
      country: data.mailingCountry ?? '',
      zipCode: data.mailingZipCode ?? '',
      state: data.mailingState ?? '',
      isUsingDrmsAddress: Boolean(data?.isUsingDrmsAddress),
    },
    documents: data.document.map((doc) => ({ name: doc.document })),
    disableFormEdit: data?.disableFormEdit,
  };

  return companyDetail;
}

export const transformPostCompanyDetail = (companyDetail: CompanyDetail): RawCompanyDetail => {
  // TODO:  implement after api is ready

  return {} as unknown as RawCompanyDetail;
};
