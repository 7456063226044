import { useTranslation } from "react-i18next";
import { MailingAddress } from "../../api/types";
import { FormFieldSelect } from "../../components/FormFieldSelect";
import { FormFieldText } from "../../components/FormFieldText";
import { NATION_INFOS } from "../../constants/SelectionOptions";
import { useValidateCaller } from "../../hooks-ui/useValidateCaller";

type MailingAddressTabProps = {
  readonly: boolean;
  mailingAddress?: Partial<MailingAddress>;
  onChange?: (mailingAddress: Partial<MailingAddress>) => void;
};

export function MailingAddressTab({ readonly, mailingAddress, onChange }: MailingAddressTabProps) {
  const { t } = useTranslation();
  const { validateCaller } = useValidateCaller();

  const handleFormChange = <K extends keyof MailingAddress>(key: K, value: MailingAddress[K]) => {
    const newInfo = { ...mailingAddress, [key]: value };
    if (key === 'isUsingDrmsAddress' && value) {
      newInfo.address = '';
      newInfo.city = '';
      newInfo.country = '';
      newInfo.state = '';
      newInfo.zipCode = '';
    }
    onChange?.(newInfo);
  };

  return (
    <>
      <div>
        <label
          htmlFor={`isDrmsAddressId`}
          className="flex gap-1 items-center"
        >
          <input
            type="checkbox"
            name="type"
            id={`isDrmsAddressId`}
            disabled={readonly}
            className="accent-primary w-5 h-5 border-surface rounded-lg"
            checked={Boolean(mailingAddress?.isUsingDrmsAddress)}
            onChange={(event) => {
              handleFormChange(
                'isUsingDrmsAddress',
                event.currentTarget.checked,
              );
            }}
          />
          <span>{t('Using DRMS Address')}</span>
        </label>
      </div>
      <div className="gap-x-4 gap-y-6 grid grid-cols-2 xl:grid-cols-6 mt-4">
        <div>
          <FormFieldText
            isFixedValue={readonly || Boolean(mailingAddress?.isUsingDrmsAddress)}
            label={t('State')}
            validateCaller={validateCaller}
            id="state"
            value={mailingAddress?.state}
            onChange={(value) => handleFormChange('state', value)}
          />
        </div>

        <div>
          <FormFieldSelect
            isFixedValue={readonly || Boolean(mailingAddress?.isUsingDrmsAddress)}
            optionInfos={NATION_INFOS}
            label={t('Country')}
            isRequired
            validateCaller={validateCaller}
            id="country"
            value={mailingAddress?.country}
            onChange={(value) => handleFormChange('country', value)}
          />
        </div>

        <div>
          <FormFieldText
            isFixedValue={readonly || Boolean(mailingAddress?.isUsingDrmsAddress)}
            label={t('City')}
            isRequired
            validateCaller={validateCaller}
            id="city"
            value={mailingAddress?.city}
            onChange={(value) => handleFormChange('city', value)}
          />
        </div>

        <div className="col-span-2 row-start-2 xl:row-start-1 xl:col-start-3">
          <FormFieldText
            isFixedValue={readonly || Boolean(mailingAddress?.isUsingDrmsAddress)}
            label={t('Address')}
            isRequired
            validateCaller={validateCaller}
            id="address"
            value={mailingAddress?.address}
            onChange={(value) => handleFormChange('address', value)}
          />
        </div>

        <div>
          <FormFieldText
            isFixedValue={readonly || Boolean(mailingAddress?.isUsingDrmsAddress)}
            label={t('Zip Code')}
            isRequired
            validateCaller={validateCaller}
            id="zipCode"
            value={mailingAddress?.zipCode}
            onChange={(value) => handleFormChange('zipCode', value)}
          />
        </div>
      </div>
    </>
  );
}
