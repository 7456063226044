import {
  type OrderResponseBody,
  PurchaseUnitItem,
} from "@paypal/paypal-js/types/apis/orders";
import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from "@paypal/paypal-js/types/components/buttons";
import { useRef } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { callCreateOrderCard, callCreateOrderPaypal } from "../api/payment";
import { ApiCreateOrderParam } from "../api/types";
import { Service } from "../pages/ServicesContent/ServicesContent";
import { useTranslation } from "react-i18next";

type Props = {
  items: Pick<Service, "label" | "price" | "id" | "cycleNumber">[];
  totalPrice: number;
  onCancel: () => void;
  onFinishPayment: (
    orderId: string,
    details: OrderResponseBody | undefined
  ) => void;
};
export function CheckOutPayPal(props: Props) {
  const [{ isPending }] = usePayPalScriptReducer();
  const { t } = useTranslation();
  const orderIdRef = useRef<string>("");
  const paypalTypeRef = useRef<{ type: string; body: ApiCreateOrderParam }>({
    type: "",
    body: {
      cashout: [
        {
          serviceId: 0,
          cycleNumber: 0,
        },
      ],
    },
  });

  async function onCreateOrder(
    data: CreateOrderData,
    actions: CreateOrderActions
  ) {
    const purchaseItems: PurchaseUnitItem[] = props.items.map((item) => {
      return {
        name: item.label,
        unit_amount: {
          currency_code: "USD",
          value: item.price.toString(),
        },
        quantity: "1",
      };
    });
    const orderId = await actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: props.totalPrice.toString(),
            breakdown: {
              item_total: {
                currency_code: "USD",
                value: props.totalPrice.toString(),
              },
            },
          },
          items: purchaseItems,
        },
      ],
    });
    const body: ApiCreateOrderParam = {
      transId: orderId,
      cashout: props.items.map((service) => {
        return {
          serviceId: +service.id,
          cycleNumber: service.cycleNumber,
        };
      }),
    };
    paypalTypeRef.current.type = data.paymentSource;
    paypalTypeRef.current.body = body;
    orderIdRef.current = orderId;
    return orderId;
  }

  async function onApproveOrder(
    data: OnApproveData,
    actions: OnApproveActions
  ) {
    const details = await actions.order
      ?.capture()
      .catch((e) => console.error(e));
    if (details && !!details.id && details.status === "COMPLETED") {
      if (paypalTypeRef.current.type === "card") {
        callCreateOrderCard(paypalTypeRef.current.body).catch((e) =>
          console.error(e)
        );
      } else {
        callCreateOrderPaypal(paypalTypeRef.current.body).catch((e) =>
          console.error(e)
        );
      }
    }

    props.onFinishPayment(orderIdRef.current, details || undefined);
  }

  return (
    <div className="checkout">
      {isPending ? (
        <p>LOADING...</p>
      ) : (
        <>
          <PayPalButtons
            style={{ layout: "vertical", shape: "rect" }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
            onCancel={props.onCancel}
            onError={props.onCancel}
          />
          <p className="text-center mb-4 italic opacity-50 text-sm">
            {t(
              "In case your payment card encounters an error, please use other payment methods."
            )}
          </p>
        </>
      )}
    </div>
  );
}
