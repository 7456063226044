import { MyServiceType, RawService } from "../../../api/types";
import { Service } from "../../../pages/ServicesContent/ServicesContent";

// TODO fix type
export function filterServiceToDisplay(
  allServices: RawService[],
  allMyServices: MyServiceType[]
): Service[] {
  // Logic: can register new service only when the before was Issued
  const hasBaseService = !!allMyServices.find(
    (service) =>
      service.serviceType === "Based" && service.statusPayment === "Approved"
  );
  // Logic: 1 user - 1 base service
  const servicesAfterFilterType = hasBaseService
    ? allServices.filter((service) => service.serviceType !== "Based")
    : allServices;

  const pendingService: number[] = [];
  allMyServices.forEach(
    (service: {
      statusService: string;
      statusPayment: string;
      serviceId: number;
    }) => {
      if (
        service.statusService !== "Issued" &&
        service.statusPayment !== "Rejected" &&
        service.statusPayment !== "Approved"
      ) {
        pendingService.push(service.serviceId);
      }
    }
  );
  const serviceAfterFilterTypeAndStatus = servicesAfterFilterType.filter(
    (service) => !pendingService.includes(service.id)
  );
  // Logic: price of next or last cycle
  let appliedCycleMap: Record<string, any> = {};

  allMyServices.forEach((myService: any) => {
    const found = serviceAfterFilterTypeAndStatus.find(
      (sv) => sv.id === myService.serviceId
    );
    if (!found || myService.statusPayment !== "Approved") return;
    if (!appliedCycleMap[myService.serviceId]) {
      appliedCycleMap[myService.serviceId] = { ...myService, nextCycle: 2 };
    } else {
      const oldCycle: number = appliedCycleMap[myService.serviceId].cycleNumber;
      appliedCycleMap[myService.serviceId].nextCycle += 1;
      const nextCycle = appliedCycleMap[myService.serviceId].nextCycle;
      if (myService.cycleNumber > oldCycle) {
        appliedCycleMap[myService.serviceId] = { ...myService, nextCycle };
      }
    }
  });
  return serviceAfterFilterTypeAndStatus.map((service) => {
    const hasPaid = appliedCycleMap[service.id];
    const nextCycle = appliedCycleMap?.[service.id]?.nextCycle;
    const lastCycle = service.serviceCycle[service.serviceCycle.length - 1];
    if (hasPaid) {
      if (service.serviceCycle[nextCycle - 1])
        appliedCycleMap[service.id] = service.serviceCycle[nextCycle - 1];
      else {
        appliedCycleMap[service.id].pricePerCycle = lastCycle.pricePerCycle;
        appliedCycleMap[service.id].pricePerCycleQuotation =
          lastCycle?.pricePerCycleQuotation;
      }
    } else {
      appliedCycleMap[service.id] = service.serviceCycle[0];
    }

    return {
      id: service.id,
      label: service.serviceName,
      description: service.serviceDescription,
      agents: service.serviceStep.map((step: any) => step.name),
      price:
        appliedCycleMap[service.id].pricePerCycleQuotation ??
        appliedCycleMap[service.id].pricePerCycle,
      cycleNumber: nextCycle ?? appliedCycleMap[service.id].cycleNumber,
      isQuotation: service.isQuotation ?? false,
      isQuotationSendRequest: service.isQuotationSendRequest ?? false,
      currency: "USD",
      serviceType: service.serviceType,
      serviceCycle: service.serviceCycle,
    };
  }) as Service[];
}
