import { ChangeEvent, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputFile from "src/components/InputFile";
import { IconUpload, IconUploadFile } from "src/components/icons";

type TakeOrUploadPhotoProps = {
  onUpload: (file: File[] | null) => void;
};

export default function TakeOrUploadPhoto(props: TakeOrUploadPhotoProps) {
  const translation = useTranslation();
  const uploadFileRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File[]>();

  function handleClickUpload() {
    if (uploadFileRef) {
      uploadFileRef.current?.click();
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      let files = Array.from(event.target.files);
      if (file) {
        files = Array.from(file).concat(Array.from(files));
      }
      if (files.length > 3) return;
      setFile(files);
      props.onUpload(files);
    }
  }

  return (
    <div
      className={
        "w-full flex flex-col items-center border border-primary_light rounded-xl px-2 py-6"
      }
    >
      {!file && (
        <div className={"rounded-full bg-primary_light p-4"}>
          <IconUploadFile />
        </div>
      )}

      {file && (
        <div className={"flex flex-col gap-2 items-center"}>
          {file.map((src, index) => (
            <InputFile
              key={`file${index}`}
              label={translation.t("Upload")}
              onChange={() => {
                const newFiles = file.filter((item, crIndex) => index !== crIndex);
                setFile(newFiles);
              }}
              maxSize={10}
              canDeleteFile
              accept="*"
              hiddenUploadBtn
              filePreview={src}
            />
          ))}
        </div>
      )}

      <div className={"flex flex-row gap-4 my-4"}>
        <div
          className={
            "py-4 px-6 flex flex-row gap-3 bg-primary rounded-lg cursor-pointer"
          }
          onClick={handleClickUpload}
        >
          <IconUpload className={"text-white"} />
          <input
            ref={uploadFileRef}
            className={"hidden"}
            type="file"
            accept="*"
            multiple
            onChange={handleChange}
          />
          <p className={"text-white font-bold"}>
            {translation.t("Upload file")}
          </p>
        </div>
      </div>
      <ul className={"list-disc flex flex-col items-center"}>
        <li>
          {translation.t(
            "All corners of the passport are visible against the backdrop"
          )}
        </li>
        <li>{translation.t("All passport data is legible")}</li>
        <li>
          {translation.t(
            "The photo is in color and should be a valid file (PNG, JPG, JPEG)"
          )}
        </li>
        <li>{translation.t("Maximum allowed size is 10MB")}</li>
      </ul>
    </div>
  );
}
