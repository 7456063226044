import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "src/constants/routerPaths";

import ContentFillingService from "./components/ContentFillingService";
import StepService from "./components/StepService";
import TabService from "./components/TabService";
import TagService from "./components/TagService";
import { LLCMyServiceContext } from "./context/llcMyServiceContext";
import { ServiceStatusType, TabType } from "./types/my-service.type";
import { useValidateCaller } from "../../hooks-ui/useValidateCaller";
import { useApiLLCServiceDetail } from "../../hooks-api/useLlcService";
import {
  BuildingIcon,
  DocumentIcon,
  IdentityIcon,
  MoneyIcon,
} from "../../components/icons";
import TitleContent from "../../components/TitleContent";
import { FormFieldSelect } from "../../components/FormFieldSelect";
import { getFile } from "src/api/upload";
import { AuthContext } from "src/contexts/AuthContextProvider";
import {
  DialogFailureFullscreen,
  DialogRequestingFullscreen,
} from "src/components/DialogFormStatusFullscreen";
import { useApiGetMyCompanyDetail } from "src/hooks-api/useMyCompany";

export default function LLCMyServiceContent() {
  const { t } = useTranslation();
  const { validateCaller } = useValidateCaller();
  const { setDetailFilling, listCycles } = useContext(LLCMyServiceContext);
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [cycle, setCycle] = useState<string>(
    listCycles?.[Number(id)]?.[0].value ?? "1"
  );

  const resLLCService = useApiLLCServiceDetail(Number(cycle));

  const dataService = resLLCService?.data;

  // set step mặc định 1
  useEffect(() => {
    if (dataService) {
      setDetailFilling(dataService?.serviceStep?.[0]);
    }
  }, [dataService]);

  useEffect(() => {
    if (listCycles?.[Number(id)]) setCycle(listCycles?.[Number(id)]?.[0].value);
  }, [id, listCycles]);

  const navigator = useNavigate();
  //TODO: api công ty theo user
  // @ts-ignore
  const resCompanyId = useApiGetMyCompanyDetail();

  const companyDetail = resCompanyId.data;

  const statusCorporationProfile = useMemo(() => {
    try {
      if (companyDetail?.disableFormEdit) {
        return ServiceStatusType.Confirmed;
      }
    } catch (error) {}
    return ServiceStatusType.Pending;
  }, [companyDetail]);

  const dataTab: TabType[] = [
    {
      icon: <MoneyIcon className="w-6 h-6" />,
      header: t("Payment"),
      detail:
        dataService?.statusPayment === ServiceStatusType.Approved
          ? t("Approved")
          : t("Transaction is being verified"),
      status: dataService?.statusPayment as ServiceStatusType,
      color: "#FFC327",
      id: 3,
      clickable: false,
    },
    {
      icon: <BuildingIcon className="w-6 h-6" />,
      header: t("Corporation profile"),
      detail:
        statusCorporationProfile === ServiceStatusType.Confirmed
          ? t("Click to view >")
          : t("Please complete this information >"),
      status: statusCorporationProfile as ServiceStatusType,
      color: "#5D50C6",
      id: 4,
      clickable: true,
      onClick: () => {
        // Chọn để hiển thị Màn hình chi tiết Công ty.
        navigator(RoutePaths.myCompany);
      },
    },
    {
      icon: <IdentityIcon className="w-6 h-6" />,
      header: t("KYC"),
      detail:
        user?.kycStatus === ServiceStatusType.Approved
          ? t("Click to view >")
          : t("Please complete the KYC >"),
      status: user?.kycStatus as ServiceStatusType,
      color: "#FF5722",
      id: 2,
      clickable: true,
      onClick: () => {
        // Hệ thống chuyển sang màn hình Chi tiết tài khoản.
        navigator(RoutePaths.myAccount);
      },
    },
  ];

  const onChangeCycle = (value: string) => {
    setCycle(value);
  };

  const handleClickSubmitLLCService = () => {
    resLLCService.refetch();
  };

  const handleClickSubmitCompanyId = () => {
    resCompanyId.refetch();
  };

  return (
    <>
      {(resLLCService.isFetching || resCompanyId.isFetching) && (
        <DialogRequestingFullscreen />
      )}
      {resLLCService.isError && (
        <DialogFailureFullscreen
          title="Failure!"
          subTitle={resLLCService?.error?.message}
          actionElement={
            <button
              onClick={handleClickSubmitLLCService}
              className="w-full min-w-[300px] h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
            >
              <span>{t("Try again")}</span>
            </button>
          }
        />
      )}

      {resCompanyId.isError && (
        <DialogFailureFullscreen
          title="Failure!"
          subTitle={resCompanyId?.error?.message}
          actionElement={
            <button
              onClick={handleClickSubmitCompanyId}
              className="w-full min-w-[300px] h-[52px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
            >
              <span>{t("Try again")}</span>
            </button>
          }
        />
      )}

      <div className="w-full flex grow relative overflow-y-scroll border border-l border-stroke">
        <div className="w-full grow flex flex-col p-3">
          {dataService && (
            <div className="md:p-5 lg:p-6 bg-white rounded grow overflow-y-scroll overflow-x-hidden ">
              <div className="flex gap-4 flex-col lg:flex-row justify-between">
                <TitleContent label={dataService.serviceName} />
                <div className="flex justify-between lg:justify-normal gap-md">
                  <div className="flex gap-md items-center">
                    <div>{t("Cycle")}:</div>
                    <FormFieldSelect
                      id={"cycleSelect"}
                      isRequired
                      placeholder={"--"}
                      value={cycle}
                      disabled={dataService.serviceType === "Based"}
                      optionInfos={listCycles?.[dataService.serviceId] || []}
                      onChange={onChangeCycle}
                      validateCaller={validateCaller}
                      className="w-[40px]"
                    />
                  </div>
                  <TagService
                    status={dataService.statusService as ServiceStatusType}
                  />
                </div>
              </div>
              <div className="hidden bg-[#5D50C6]/25 bg-[#094B72]/25  bg-[#FFC327]/25  bg-[#FF5722]/25 "></div>
              {/* tab */}
              <div className="mt-xl">
                <div className="grid gap-md grid-cols-3">
                  {dataTab.map((item, index) => (
                    <TabService key={item.id} item={item} />
                  ))}
                </div>
              </div>

              {/* content */}
              <>
                <div className="flex flex-col lg:flex-row gap-8 lg:gap-3 mt-6">
                  {/* step */}
                  <div className="flex flex-col w-full lg:w-[247px] gap-md">
                    {dataService?.serviceStep?.map((item: any) => (
                      <StepService key={item.id} item={item} />
                    ))}
                  </div>
                  <ContentFillingService />
                </div>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
