import { CompanyTypeValue, EntityEnding, Industry, NationPhone, NationValue } from "../api/types";
import { OptionInfo } from "../types/common";

export const NATION_INFOS: OptionInfo<NationValue>[] = [
  {value: 'United States',	label: 'United States'},
  {value: 'Hong Kong',	label: 'Hong Kong'},
  {value: 'Singapore',	label: 'Singapore'},
  // {value: 'CA',	label: 'Canada'},
  // {value: 'US',	label: 'United States'},
  // {value: 'RO',	label: 'Russia'},
  // {value: 'EG',	label: 'Egypt'},
  // {value: 'ZA',	label: 'South Africa'},
  // {value: 'GR',	label: 'Greece'},
  // {value: 'NL',	label: 'Netherlands'},
  // {value: 'BE',	label: 'Belgium'},
  // {value: 'FR',	label: 'France'},
  // {value: 'ES',	label: 'Spain'},
  // {value: 'HU',	label: 'Hungary'},
  // {value: 'IT',	label: 'Italy'},
  // {value: 'RO',	label: 'Romania'},
  // {value: 'CH',	label: 'Switzerland'},
  // {value: 'AT',	label: 'Austria'},
  // {value: 'GB',	label: 'United Kingdom'},
  // {value: 'DK',	label: 'Denmark'},
  // {value: 'SE',	label: 'Sweden'},
  // {value: 'NO',	label: 'Norway'},
  // {value: 'PL',	label: 'Poland'},
  // {value: 'DE',	label: 'Germany'},
  // {value: 'PE',	label: 'Peru'},
  // {value: 'MX',	label: 'Mexico'},
  // {value: 'CU',	label: 'Cuba'},
  // {value: 'AR',	label: 'Argentina'},
  // {value: 'BR',	label: 'Brazil'},
  // {value: 'CL',	label: 'Chile'},
  // {value: 'CO',	label: 'Colombia'},
  // {value: 'VE',	label: 'Venezuela'},
  // {value: 'MY',	label: 'Malaysia'},
  // {value: 'AU',	label: 'Australia'},
  // {value: 'ID',	label: 'Indonesia'},
  // {value: 'PH',	label: 'Philippines'},
  // {value: 'NZ',	label: 'New Zealand'},
  // {value: 'SG',	label: 'Singapore'},
  // {value: 'TZ',	label: 'Thái Lan'},
  // {value: 'KZ', label: 'Kazakhstan'},
  // {value: 'KZ',	label: 'Kazakhstan'},
  // {value: 'JP',	label: 'Japan'},
  // {value: 'HT',	label: 'Hàn Quốc'},
  // {value: 'VN',	label: 'Vietnam'},
  // {value: 'CN',	label: 'China'},
  // {value: 'TR',	label: 'Turkey'},
  // {value: 'IN',	label: 'India'},
  // {value: 'PK',	label: 'Pakistan'},
  // {value: 'AF',	label: 'Afghanistan'},
  // {value: 'LK',	label: 'Sri Lanka'},
  // {value: 'MM',	label: 'Myanmar'},
  // {value: 'ID',	label: 'Iran'},
  // {value: 'MA',	label: 'Morocco'},
  // {value: 'DZ',	label: 'Algeria'},
  // {value: 'TN',	label: 'Tunisia'},
  // {value: 'LR',	label: 'Libya'},
  // {value: 'GM',	label: 'Gambia'},
  // {value: 'SN',	label: 'Senegal'},
  // {value: 'MR',	label: 'Mauritania'},
  // {value: 'ML',	label: 'Mali'},
  // {value: 'GN',	label: 'Guinea'},
  // {value: 'CI',	label: 'Côte d’lvoire'},
  // {value: 'IT',	label: 'Ivory Coast'},
  // {value: 'BF',	label: 'Burkina Faso'},
  // {value: 'NE',	label: 'Niger Republic'},
  // {value: 'TG',	label: 'Togo'},
  // {value: 'BJ',	label: 'Benin'},
  // {value: 'MU',	label: 'Mauritius'},
  // {value: 'LR',	label: 'Liberia'},
  // {value: 'SL',	label: 'Sierra Leone'},
  // {value: 'GH',	label: 'Ghana'},
  // {value: 'NG',	label: 'Nigeria'},
  // {value: 'TD',	label: 'Chad'},
  // {value: 'CF',	label: 'Central African Republic'},
  // {value: 'CM',	label: 'Cameroon'},
  // {value: 'CV',	label: 'Cape Verde'},
  // {value: 'SY',	label: 'Sใo Tom้ & Principe'},
  // {value: 'GQ',	label: 'Equatorial Guinea'},
  // {value: 'GA',	label: 'Gabon'},
  // {value: 'CG',	label: 'Congo'},
  // {value: 'BS',	label: 'Bahamas'},
  // {value: 'CG',	label: 'Congo, Dem. Rep. of'},
  // {value: 'YE',	label: 'Zaire'},
  // {value: 'AO',	label: 'Angola'},
  // {value: 'GW',	label: 'Guinea-Bissau'},
  // {value: 'TD',	label: 'Chagos Archipelago'},
  // {value: 'BB',	label: 'Barbados'},
  // {value: 'SC',	label: 'Seychelles'},
  // {value: 'SD',	label: 'Sudan'},
  // {value: 'RW',	label: 'Rwanda'},
  // {value: 'ET',	label: 'Ethiopia'},
  // {value: 'SO',	label: 'Somalia'},
  // {value: 'DJ',	label: 'Djibouti'},
  // {value: 'KE',	label: 'Kenya'},
  // {value: 'TJ',	label: 'Tanzania'},
  // {value: 'UG',	label: 'Uganda'},
  // {value: 'BI',	label: 'Burundi'},
  // {value: 'MZ',	label: 'Mozambique'},
  // {value: 'ZM',	label: 'Zambia'},
  // {value: 'MG',	label: 'Madagascar'},
  // {value: 'RE',	label: 'Reunion Island'},
  // {value: 'ZW',	label: 'Zimbabwe'},
  // {value: 'NA',	label: 'Namibia'},
  // {value: 'MW',	label: 'Malawi'},
  // {value: 'LS',	label: 'Lesotho'},
  // {value: 'BW',	label: 'Botswana'},
  // {value: 'SZ',	label: 'Swaziland'},
  // {value: 'KM',	label: 'Comoros'},
  // {value: 'AW',	label: 'Aruba'},
  // {value: 'ET',	label: 'Faeroe Islands'},
  // {value: 'GL',	label: 'Greenland'},
  // {value: 'GI',	label: 'Gibraltar'},
  // {value: 'PT',	label: 'Portugal'},
  // {value: 'LU',	label: 'Luxembourg'},
  // {value: 'IE',	label: 'Ireland'},
  // {value: 'IS',	label: 'Iceland'},
  // {value: 'AL',	label: 'Albania'},
  // {value: 'MT',	label: 'Malta'},
  // {value: 'CY',	label: 'Cyprus'},
  // {value: 'FI',	label: 'Finland'},
  // {value: 'BG',	label: 'Bulgaria'},
  // {value: 'LT',	label: 'Lithuania'},
  // {value: 'LV',	label: 'Latvia'},
  // {value: 'EE',	label: 'Estonia'},
  // {value: 'FM',	label: 'Moldova'},
  // {value: 'AM',	label: 'Armenia'},
  // {value: 'BY',	label: 'Belarus'},
  // {value: 'AD',	label: 'Andorra'},
  // {value: 'MC',	label: 'Monaco'},
  // {value: 'SM',	label: 'San Marino'},
  // {value: 'UA',	label: 'Ukraine'},
  // {value: 'MN',	label: 'Montenegro & Serbia'},
  // {value: 'YE',	label: 'Yugoslavia'},
  // {value: 'HR',	label: 'Croatia'},
  // {value: 'SI',	label: 'Slovenia'},
  // {value: 'BO',	label: 'Bosnia & Herzegovina'},
  // {value: 'MO',	label: 'Macedonia'},
  // {value: 'CZ',	label: 'Czech Republic'},
  // {value: 'SG',	label: 'Slovak Republic'},
  // {value: 'LI',	label: 'Liechtenstein'},
  // {value: 'BM',	label: 'Bermuda'},
  // {value: 'ET',	label: 'Falkland Islands'},
  // {value: 'BZ',	label: 'Belize'},
  // {value: 'GT',	label: 'Guatemala'},
  // {value: 'SV',	label: 'El Salvador'},
  // {value: 'HN',	label: 'Honduras'},
  // {value: 'NI',	label: 'Nicaragua'},
  // {value: 'CR',	label: 'Costa Rica'},
  // {value: 'PA',	label: 'Panama'},
  // {value: 'HT',	label: 'Haiti'},
  // {value: 'GP',	label: 'Guadeloupe'},
  // {value: 'BO',	label: 'Bolivia'},
  // {value: 'GY',	label: 'Guyana'},
  // {value: 'EC',	label: 'Ecuador'},
  // {value: 'GF',	label: 'French Guiana'},
  // {value: 'PY',	label: 'Paraguay'},
  // {value: 'FR',	label: 'French Antilles'},
  // {value: 'MQ',	label: 'Martinique'},
  // {value: 'SR',	label: 'Suriname'},
  // {value: 'UY',	label: 'Uruguay'},
  // {value: 'AN',	label: 'Netherlands Antilles'},
  // {value: 'BN',	label: 'Brunei Darussalam'},
  // {value: 'PG',	label: 'Papua New Guinea'},
  // {value: 'TO',	label: 'Tonga'},
  // {value: 'SB',	label: 'Solomon Islands'},
  // {value: 'VU',	label: 'Vanuatu'},
  // {value: 'FJ',	label: 'Fiji'},
  // {value: 'PW',	label: 'Palau'},
  // {value: 'CK',	label: 'Cook Islands'},
  // {value: 'AS',	label: 'American Samoa'},
  // {value: 'EH',	label: 'Western Samoa'},
  // {value: 'NC',	label: 'New Caledonia'},
  // {value: 'TV',	label: 'Tuvalu'},
  // {value: 'PF',	label: 'French Polynesia'},
  // {value: 'MH',	label: 'Marshall Islands'},
  // {value: 'FM',	label: 'Midway Islands'},
  // {value: 'TO',	label: 'Triều Tiên'},
  // {value: 'HK',	label: 'Hong Kong'},
  // {value: 'MO',	label: 'Macau'},
  // {value: 'KH',	label: 'Cambodia'},
  // {value: 'LA',	label: 'Laos'},
  // {value: 'BD',	label: 'Bangladesh'},
  // {value: 'SY',	label: 'Taiwan'},
  // {value: 'MV',	label: 'Maldives'},
  // {value: 'LB',	label: 'Lebanon'},
  // {value: 'JO',	label: 'Jordan'},
  // {value: 'CH',	label: 'Syria'},
  // {value: 'IQ',	label: 'Iraq'},
  // {value: 'KW',	label: 'Kuwait'},
  // {value: 'SA',	label: 'Saudi Arabia'},
  // {value: 'YE',	label: 'Yemen'},
  // {value: 'OM',	label: 'Oman'},
  // {value: 'AE',	label: 'United Arab Emirates'},
  // {value: 'IL',	label: 'Israel'},
  // {value: 'BH',	label: 'Bahrain'},
  // {value: 'QA',	label: 'Qatar'},
  // {value: 'BT',	label: 'Bhutan'},
  // {value: 'MN',	label: 'Mongolia'},
  // {value: 'NP',	label: 'Nepal'},
  // {value: 'TJ',	label: 'Tajikistan'},
  // {value: 'TM',	label: 'Turkmenistan'},
  // {value: 'AZ',	label: 'Azerbaijan'},
  // {value: 'GE',	label: 'Georgia'},
  // {value: 'KG',	label: 'Kyrgyzstan'},
  // {value: 'UZ',	label: 'Uzbekistan'},
  // {value: 'AI',	label: 'Anguilla'},
  // {value: 'AQ',	label: 'Antigua & Barbuda'},
  // {value: 'VG',	label: 'Virgin Islands, British'},
  // {value: 'VI',	label: 'Virgin Islands, U.S'},
  // {value: 'KY',	label: 'Cayman Islands'},
  // {value: 'GD',	label: 'Grenada'},
  // {value: 'TM',	label: 'Turks & Caicos Islands'},
  // {value: 'MS',	label: 'Montserrat'},
  // {value: 'NF',	label: 'Northern Mariana Isl'},
  // {value: 'GU',	label: 'Guam'},
  // {value: 'LK',	label: 'St. Lucia'},
  // {value: 'DM',	label: 'Dominica'},
  // {value: 'LK',	label: 'St. Vincents & Grenadines'},
  // {value: 'PR',	label: 'Puerto Rico'},
  // {value: 'DO',	label: 'Dominican Republic'},
  // {value: 'TO',	label: 'Trinidad & Tobago'},
  // {value: 'LK',	label: 'St. Kitts & Nevis'},
  // {value: 'JM',	label: 'Jamaica'},
]

export const NATION_PHONE_INFOS: OptionInfo<NationPhone>[] = [
  {value: '+1',	label: '+1'	, iconElement: <div>US, CA</div>},
  {value: '+7',	label: '+7'	, iconElement: <div>RO</div>},
  {value: '+20',	label: '+20'	, iconElement: <div>EG</div>},
  {value: '+27',	label: '+27'	, iconElement: <div>ZA</div>},
  {value: '+30',	label: '+30'	, iconElement: <div>GR</div>},
  {value: '+31',	label: '+31'	, iconElement: <div>NL</div>},
  {value: '+32',	label: '+32'	, iconElement: <div>BE</div>},
  {value: '+33',	label: '+33'	, iconElement: <div>FR</div>},
  {value: '+34',	label: '+34'	, iconElement: <div>ES</div>},
  {value: '+36',	label: '+36'	, iconElement: <div>HU</div>},
  {value: '+39',	label: '+39'	, iconElement: <div>IT</div>},
  {value: '+40',	label: '+40'	, iconElement: <div>RO</div>},
  {value: '+41',	label: '+41'	, iconElement: <div>CH</div>},
  {value: '+43',	label: '+43'	, iconElement: <div>AT</div>},
  {value: '+44',	label: '+44'	, iconElement: <div>GB</div>},
  {value: '+45',	label: '+45'	, iconElement: <div>DK</div>},
  {value: '+46',	label: '+46'	, iconElement: <div>SE</div>},
  {value: '+47',	label: '+47'	, iconElement: <div>NO</div>},
  {value: '+48',	label: '+48'	, iconElement: <div>PL</div>},
  {value: '+49',	label: '+49'	, iconElement: <div>DE</div>},
  {value: '+51',	label: '+51'	, iconElement: <div>PE</div>},
  {value: '+52',	label: '+52'	, iconElement: <div>MX</div>},
  {value: '+53',	label: '+53'	, iconElement: <div>CU</div>},
  {value: '+54',	label: '+54'	, iconElement: <div>AR</div>},
  {value: '+55',	label: '+55'	, iconElement: <div>BR</div>},
  {value: '+56',	label: '+56'	, iconElement: <div>CL</div>},
  {value: '+57',	label: '+57'	, iconElement: <div>CO</div>},
  {value: '+58',	label: '+58'	, iconElement: <div>VE</div>},
  {value: '+60',	label: '+60'	, iconElement: <div>MY</div>},
  {value: '+61',	label: '+61'	, iconElement: <div>AU</div>},
  {value: '+62',	label: '+62'	, iconElement: <div>ID</div>},
  {value: '+63',	label: '+63'	, iconElement: <div>PH</div>},
  {value: '+64',	label: '+64'	, iconElement: <div>NZ</div>},
  {value: '+65',	label: '+65'	, iconElement: <div>SG</div>},
  {value: '+66',	label: '+66'	, iconElement: <div>TZ</div>},
  {value: '+76', label: '+76'	, iconElement: <div>KZ</div>},
  {value: '+77',	label: '+77'	, iconElement: <div>KZ</div>},
  {value: '+81',	label: '+81'	, iconElement: <div>JP</div>},
  {value: '+82',	label: '+82'	, iconElement: <div>HT</div>},
  {value: '+84',	label: '+84'	, iconElement: <div>VN</div>},
  {value: '+86',	label: '+86'	, iconElement: <div>CN</div>},
  {value: '+90',	label: '+90'	, iconElement: <div>TR</div>},
  {value: '+91',	label: '+91'	, iconElement: <div>IN</div>},
  {value: '+92',	label: '+92'	, iconElement: <div>PK</div>},
  {value: '+93',	label: '+93'	, iconElement: <div>AF</div>},
  {value: '+94',	label: '+94'	, iconElement: <div>LK</div>},
  {value: '+95',	label: '+95'	, iconElement: <div>MM</div>},
  {value: '+98',	label: '+98'	, iconElement: <div>ID</div>},
  {value: '+212',	label: '+212'	, iconElement: <div>MA</div>},
  {value: '+213',	label: '+213'	, iconElement: <div>DZ</div>},
  {value: '+216',	label: '+216'	, iconElement: <div>TN</div>},
  {value: '+218',	label: '+218'	, iconElement: <div>LR</div>},
  {value: '+220',	label: '+220'	, iconElement: <div>GM</div>},
  {value: '+221',	label: '+221'	, iconElement: <div>SN</div>},
  {value: '+222',	label: '+222'	, iconElement: <div>MR</div>},
  {value: '+223',	label: '+223'	, iconElement: <div>ML</div>},
  {value: '+224',	label: '+224'	, iconElement: <div>GN</div>},
  {value: '+225',	label: '+225'	, iconElement: <div>CI</div>},
  {value: '+225',	label: '+225'	, iconElement: <div>IT</div>},
  {value: '+226',	label: '+226'	, iconElement: <div>BF</div>},
  {value: '+227',	label: '+227'	, iconElement: <div>NE</div>},
  {value: '+228',	label: '+228'	, iconElement: <div>TG</div>},
  {value: '+229',	label: '+229'	, iconElement: <div>BJ</div>},
  {value: '+230',	label: '+230'	, iconElement: <div>MU</div>},
  {value: '+231',	label: '+231'	, iconElement: <div>LR</div>},
  {value: '+232',	label: '+232'	, iconElement: <div>SL</div>},
  {value: '+233',	label: '+233'	, iconElement: <div>GH</div>},
  {value: '+234',	label: '+234'	, iconElement: <div>NG</div>},
  {value: '+235',	label: '+235'	, iconElement: <div>TD</div>},
  {value: '+236',	label: '+236'	, iconElement: <div>CF</div>},
  {value: '+237',	label: '+237'	, iconElement: <div>CM</div>},
  {value: '+238',	label: '+238'	, iconElement: <div>CV</div>},
  {value: '+239',	label: '+239'	, iconElement: <div>SY</div>},
  {value: '+240',	label: '+240'	, iconElement: <div>GQ</div>},
  {value: '+241',	label: '+241'	, iconElement: <div>GA</div>},
  {value: '+242',	label: '+242'	, iconElement: <div>CG</div>},
  {value: '+243',	label: '+243'	, iconElement: <div>BS</div>},
  {value: '+243',	label: '+243'	, iconElement: <div>CG</div>},
  {value: '+243',	label: '+243'	, iconElement: <div>YE</div>},
  {value: '+244',	label: '+244'	, iconElement: <div>AO</div>},
  {value: '+245',	label: '+245'	, iconElement: <div>GW</div>},
  {value: '+246',	label: '+246'	, iconElement: <div>TD</div>},
  {value: '+247',	label: '+247'	, iconElement: <div>BB</div>},
  {value: '+248',	label: '+248'	, iconElement: <div>SC</div>},
  {value: '+249',	label: '+249'	, iconElement: <div>SD</div>},
  {value: '+250',	label: '+250'	, iconElement: <div>RW</div>},
  {value: '+251',	label: '+251'	, iconElement: <div>ET</div>},
  {value: '+252',	label: '+252'	, iconElement: <div>SO</div>},
  {value: '+253',	label: '+253'	, iconElement: <div>DJ</div>},
  {value: '+254',	label: '+254'	, iconElement: <div>KE</div>},
  {value: '+255',	label: '+255'	, iconElement: <div>TJ</div>},
  {value: '+256',	label: '+256'	, iconElement: <div>UG</div>},
  {value: '+257',	label: '+257'	, iconElement: <div>BI</div>},
  {value: '+258',	label: '+258'	, iconElement: <div>MZ</div>},
  {value: '+260',	label: '+260'	, iconElement: <div>ZM</div>},
  {value: '+261',	label: '+261'	, iconElement: <div>MG</div>},
  {value: '+262',	label: '+262'	, iconElement: <div>RE</div>},
  {value: '+263',	label: '+263'	, iconElement: <div>ZW</div>},
  {value: '+264',	label: '+264'	, iconElement: <div>NA</div>},
  {value: '+265',	label: '+265'	, iconElement: <div>MW</div>},
  {value: '+266',	label: '+266'	, iconElement: <div>LS</div>},
  {value: '+267',	label: '+267'	, iconElement: <div>BW</div>},
  {value: '+268',	label: '+268'	, iconElement: <div>SZ</div>},
  {value: '+269',	label: '+269'	, iconElement: <div>KM</div>},
  {value: '+297',	label: '+297'	, iconElement: <div>AW</div>},
  {value: '+298',	label: '+298'	, iconElement: <div>ET</div>},
  {value: '+299',	label: '+299'	, iconElement: <div>GL</div>},
  {value: '+350',	label: '+350'	, iconElement: <div>GI</div>},
  {value: '+351',	label: '+351'	, iconElement: <div>PT</div>},
  {value: '+352',	label: '+352'	, iconElement: <div>LU</div>},
  {value: '+353',	label: '+353'	, iconElement: <div>IE</div>},
  {value: '+354',	label: '+354'	, iconElement: <div>IS</div>},
  {value: '+355',	label: '+355'	, iconElement: <div>AL</div>},
  {value: '+356',	label: '+356'	, iconElement: <div>MT</div>},
  {value: '+357',	label: '+357'	, iconElement: <div>CY</div>},
  {value: '+358',	label: '+358'	, iconElement: <div>FI</div>},
  {value: '+359',	label: '+359'	, iconElement: <div>BG</div>},
  {value: '+370',	label: '+370'	, iconElement: <div>LT</div>},
  {value: '+371',	label: '+371'	, iconElement: <div>LV</div>},
  {value: '+372',	label: '+372'	, iconElement: <div>EE</div>},
  {value: '+373',	label: '+373'	, iconElement: <div>FM</div>},
  {value: '+374',	label: '+374'	, iconElement: <div>AM</div>},
  {value: '+375',	label: '+375'	, iconElement: <div>BY</div>},
  {value: '+376',	label: '+376'	, iconElement: <div>AD</div>},
  {value: '+377',	label: '+377'	, iconElement: <div>MC</div>},
  {value: '+378',	label: '+378'	, iconElement: <div>SM</div>},
  {value: '+380',	label: '+380'	, iconElement: <div>UA</div>},
  {value: '+381',	label: '+381'	, iconElement: <div>MN</div>},
  {value: '+381',	label: '+381'	, iconElement: <div>YE</div>},
  {value: '+385',	label: '+385'	, iconElement: <div>HR</div>},
  {value: '+386',	label: '+386'	, iconElement: <div>SI</div>},
  {value: '+387',	label: '+387'	, iconElement: <div>BO</div>},
  {value: '+389',	label: '+389'	, iconElement: <div>MO</div>},
  {value: '+420',	label: '+420'	, iconElement: <div>CZ</div>},
  {value: '+421',	label: '+421'	, iconElement: <div>SG</div>},
  {value: '+423',	label: '+423'	, iconElement: <div>LI</div>},
  {value: '+442',	label: '+442'	, iconElement: <div>BM</div>},
  {value: '+500',	label: '+500'	, iconElement: <div>ET</div>},
  {value: '+501',	label: '+501'	, iconElement: <div>BZ</div>},
  {value: '+502',	label: '+502'	, iconElement: <div>GT</div>},
  {value: '+503',	label: '+503'	, iconElement: <div>SV</div>},
  {value: '+504',	label: '+504'	, iconElement: <div>HN</div>},
  {value: '+505',	label: '+505'	, iconElement: <div>NI</div>},
  {value: '+506',	label: '+506'	, iconElement: <div>CR</div>},
  {value: '+507',	label: '+507'	, iconElement: <div>PA</div>},
  {value: '+509',	label: '+509'	, iconElement: <div>HT</div>},
  {value: '+590',	label: '+590'	, iconElement: <div>GP</div>},
  {value: '+591',	label: '+591'	, iconElement: <div>BO</div>},
  {value: '+592',	label: '+592'	, iconElement: <div>GY</div>},
  {value: '+593',	label: '+593'	, iconElement: <div>EC</div>},
  {value: '+594',	label: '+594'	, iconElement: <div>GF</div>},
  {value: '+595',	label: '+595'	, iconElement: <div>PY</div>},
  {value: '+596',	label: '+596'	, iconElement: <div>FR</div>},
  {value: '+596',	label: '+596'	, iconElement: <div>MQ</div>},
  {value: '+597',	label: '+597'	, iconElement: <div>SR</div>},
  {value: '+598',	label: '+598'	, iconElement: <div>UY</div>},
  {value: '+599',	label: '+599'	, iconElement: <div>AN</div>},
  {value: '+673',	label: '+673'	, iconElement: <div>BN</div>},
  {value: '+675',	label: '+675'	, iconElement: <div>PG</div>},
  {value: '+676',	label: '+676'	, iconElement: <div>TO</div>},
  {value: '+677',	label: '+677'	, iconElement: <div>SB</div>},
  {value: '+678',	label: '+678'	, iconElement: <div>VU</div>},
  {value: '+679',	label: '+679'	, iconElement: <div>FJ</div>},
  {value: '+680',	label: '+680'	, iconElement: <div>PW</div>},
  {value: '+682',	label: '+682'	, iconElement: <div>CK</div>},
  {value: '+684',	label: '+684'	, iconElement: <div>AS</div>},
  {value: '+685',	label: '+685'	, iconElement: <div>EH</div>},
  {value: '+687',	label: '+687'	, iconElement: <div>NC</div>},
  {value: '+688',	label: '+688'	, iconElement: <div>TV</div>},
  {value: '+689',	label: '+689'	, iconElement: <div>PF</div>},
  {value: '+692',	label: '+692'	, iconElement: <div>MH</div>},
  {value: '+808',	label: '+808'	, iconElement: <div>FM</div>},
  {value: '+850',	label: '+850'	, iconElement: <div>TO</div>},
  {value: '+852',	label: '+852'	, iconElement: <div>HK</div>},
  {value: '+853',	label: '+853'	, iconElement: <div>MO</div>},
  {value: '+855',	label: '+855'	, iconElement: <div>KH</div>},
  {value: '+856',	label: '+856'	, iconElement: <div>LA</div>},
  {value: '+880',	label: '+880'	, iconElement: <div>BD</div>},
  {value: '+886',	label: '+886'	, iconElement: <div>SY</div>},
  {value: '+960',	label: '+960'	, iconElement: <div>MV</div>},
  {value: '+961',	label: '+961'	, iconElement: <div>LB</div>},
  {value: '+962',	label: '+962'	, iconElement: <div>JO</div>},
  {value: '+963',	label: '+963'	, iconElement: <div>CH</div>},
  {value: '+964',	label: '+964'	, iconElement: <div>IQ</div>},
  {value: '+965',	label: '+965'	, iconElement: <div>KW</div>},
  {value: '+966',	label: '+966'	, iconElement: <div>SA</div>},
  {value: '+967',	label: '+967'	, iconElement: <div>YE</div>},
  {value: '+968',	label: '+968'	, iconElement: <div>OM</div>},
  {value: '+971',	label: '+971'	, iconElement: <div>AE</div>},
  {value: '+972',	label: '+972'	, iconElement: <div>IL</div>},
  {value: '+973',	label: '+973'	, iconElement: <div>BH</div>},
  {value: '+974',	label: '+974'	, iconElement: <div>QA</div>},
  {value: '+975',	label: '+975'	, iconElement: <div>BT</div>},
  {value: '+976',	label: '+976'	, iconElement: <div>MN</div>},
  {value: '+977',	label: '+977'	, iconElement: <div>NP</div>},
  {value: '+992',	label: '+992'	, iconElement: <div>TJ</div>},
  {value: '+993',	label: '+993'	, iconElement: <div>TM</div>},
  {value: '+994',	label: '+994'	, iconElement: <div>AZ</div>},
  {value: '+995',	label: '+995'	, iconElement: <div>GE</div>},
  {value: '+996',	label: '+996'	, iconElement: <div>KG</div>},
  {value: '+998',	label: '+998'	, iconElement: <div>UZ</div>},
  {value: '+1264',	label: '+1264'	, iconElement: <div>AI</div>},
  {value: '+1268',	label: '+1268'	, iconElement: <div>AQ</div>},
  {value: '+1284',	label: '+1284'	, iconElement: <div>VG</div>},
  {value: '+1340',	label: '+1340' , iconElement: <div>VI</div>},
  {value: '+1345',	label: '+1345'	, iconElement: <div>KY</div>},
  {value: '+1473',	label: '+1473'	, iconElement: <div>GD</div>},
  {value: '+1649',	label: '+1649'	, iconElement: <div>TM</div>},
  {value: '+1664',	label: '+1664'	, iconElement: <div>MS</div>},
  {value: '+1670',	label: '+1670' , iconElement: <div>NF</div>},
  {value: '+1671',	label: '+1671'	, iconElement: <div>GU</div>},
  {value: '+1758',	label: '+1758'	, iconElement: <div>LK</div>},
  {value: '+1767',	label: '+1767'	, iconElement: <div>DM</div>},
  {value: '+1784',	label: '+1784'	, iconElement: <div>LK</div>},
  {value: '+1787',	label: '+1787'	, iconElement: <div>PR</div>},
  {value: '+1809',	label: '+1809'	, iconElement: <div>DO</div>},
  {value: '+1868',	label: '+1868'	, iconElement: <div>TO</div>},
  {value: '+1869',	label: '+1869'	, iconElement: <div>LK</div>},
  {value: '+1876',	label: '+1876'	, iconElement: <div>JM</div>},
]

export const COMPANY_TYPE_INFOS: OptionInfo<CompanyTypeValue>[] = [
  {value: 'LLC', label: 'LLC (Limited Liability Company)'},
  {value: 'PLC', label: 'PLC (Private Limited Company)'},
]

export const INDUSTRY_INFOS: OptionInfo<Industry>[] = [
  {
    value:
      'RETAIL SALE OF CLOTHING, FOOTWEAR AND LEATHER ARTICLES N.E.C.',
    label:
      'RETAIL SALE OF CLOTHING, FOOTWEAR AND LEATHER ARTICLES N.E.C.',
  },
  {
    value:
      'RETAIL SALE OF ELECTRICAL HOUSEHOLD APPLIANCES, FURNITURE, LIGHTING EQUIPMENT AND OTHER HOUSEHOLD ARTICLES N.E.C.',
    label:
      'RETAIL SALE OF ELECTRICAL HOUSEHOLD APPLIANCES, FURNITURE, LIGHTING EQUIPMENT AND OTHER HOUSEHOLD ARTICLES N.E.C.',
  },
  {
    value: 'ONLINE MARKETPLACES FOR GOODS (INCLUDING FOOD)',
    label: 'ONLINE MARKETPLACES FOR GOODS (INCLUDING FOOD)',
  },
  {
    value:
      'WHOLESALE OF FURNITURE, HOME FURNISHINGS AND OTHER HOUSEHOLD EQUIPMENT N.E.C.',
    label:
      'WHOLESALE OF FURNITURE, HOME FURNISHINGS AND OTHER HOUSEHOLD EQUIPMENT N.E.C.',
  },
  {
    value: 'OTHER HOLDING COMPANIES',
    label: 'OTHER HOLDING COMPANIES',
  },
  {
    value: 'MANAGEMENT CONSULTANCY SERVICES N.E.C.',
    label: 'MANAGEMENT CONSULTANCY SERVICES N.E.C.',
  },
  {
    value:
      'DEVELOPMENT OF SOFTWARE AND APPLICATIONS (EXCEPT GAMES AND CYBERSECURITY)',
    label:
      'DEVELOPMENT OF SOFTWARE AND APPLICATIONS (EXCEPT GAMES AND CYBERSECURITY)',
  },
  {
    value: 'INFORMATION TECHNOLOGY CONSULTANCY (EXCEPT CYBERSECURITY)',
    label: 'INFORMATION TECHNOLOGY CONSULTANCY (EXCEPT CYBERSECURITY)',
  },
  {
    value: 'WHOLESALE TRADE OF A VARIETY OF GOODS WITHOUT DOMINANT',
    label: 'WHOLESALE TRADE OF A VARIETY OF GOODS WITHOUT DOMINANT',
  },
  {
    value:
      'WHOLESALE ON A FEE OR COMMISSION BASIS (EXCLUDING ONLINE MARKETPLACES',
    label:
      'WHOLESALE ON A FEE OR COMMISSION BASIS (EXCLUDING ONLINE MARKETPLACES',
  },
  {
    value: 'ADVERTISING ACTIVITIES',
    label: 'ADVERTISING ACTIVITIES',
  },
  {
    value:
      'SERVICE ACTIVITIES RELATED TO PRINTING N.E.C. (INCLUDING PLATE-MAKING)',
    label:
      'SERVICE ACTIVITIES RELATED TO PRINTING N.E.C. (INCLUDING PLATE-MAKING)',
  },
  {
    value: 'FASHION (INCLUDING ACCESSORIES) DESIGN SERVICES',
    label: 'FASHION (INCLUDING ACCESSORIES) DESIGN SERVICES',
  },
  {
    value: 'RETAIL SALE IN OTHER NON-SPECIALISED STORES N.E.C.',
    label: 'RETAIL SALE IN OTHER NON-SPECIALISED STORES N.E.C.',
  },
  {
    value: 'PUBLIC RELATIONS, MARKETING AND BRAND CONSULTANCY SERVICES',
    label: 'PUBLIC RELATIONS, MARKETING AND BRAND CONSULTANCY SERVICES',
  },
  {
    value: 'OTHER PUBLISHING ACTIVITIES (EXCLUDING ONLINE MARKETPLACES)',
    label: 'OTHER PUBLISHING ACTIVITIES (EXCLUDING ONLINE MARKETPLACES)',
  },
  {
    value: 'WHOLESALE OF OTHER MACHINERY AND EQUIPMENT N.E.C.',
    label: 'WHOLESALE OF OTHER MACHINERY AND EQUIPMENT N.E.C.',
  },
  {
    value: 'RENOVATION CONTRACTORS',
    label: 'RENOVATION CONTRACTORS',
  },
  {
    value:
      'BEAUTY SALONS AND SPAS (INCLUDING SLIMMING, SKIN CARE AND HAIR CARE CENTRES)',
    label:
      'BEAUTY SALONS AND SPAS (INCLUDING SLIMMING, SKIN CARE AND HAIR CARE CENTRES)',
  },
  {
    value:
      'PRODUCTION OF ADVERTISEMENTS, CORPORATE VIDEOS AND EVENT VIDEOS',
    label:
      'PRODUCTION OF ADVERTISEMENTS, CORPORATE VIDEOS AND EVENT VIDEOS',
  },
  {
    value:
      'OTHER ACTIVITIES',
    label:
      'OTHER ACTIVITIES',
  },
];

export const APPLIED_STATES: OptionInfo<string>[] = [
  {value: 'Colorado', label: 'Colorado'},
  {value: 'Wyoming', label: 'Wyoming'},
  {value: 'Delaware', label: 'Delaware'},
]

export const APPLIED_NATIONS: OptionInfo<string>[] = [
  {
    value: 'Albania',
    label: 'Albania',
  },
  {
    value: 'Algeria',
    label: 'Algeria',
  },
  {
    value: 'American Samoa',
    label: 'American Samoa',
  },
  {
    value: 'Andorra',
    label: 'Andorra',
  },
  {
    value: 'Angola',
    label: 'Angola',
  },
  {
    value: 'Anguilla',
    label: 'Anguilla',
  },
  {
    value: 'Antigua & Barbuda',
    label: 'Antigua & Barbuda',
  },
  {
    value: 'Argentina',
    label: 'Argentina',
  },
  {
    value: 'Armenia',
    label: 'Armenia',
  },
  {
    value: 'Aruba',
    label: 'Aruba',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'Austria',
    label: 'Austria',
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
  },
  {
    value: 'Bahamas',
    label: 'Bahamas',
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
  },
  {
    value: 'Barbados',
    label: 'Barbados',
  },
  {
    value: 'Belarus',
    label: 'Belarus',
  },
  {
    value: 'Belgium',
    label: 'Belgium',
  },
  {
    value: 'Belize',
    label: 'Belize',
  },
  {
    value: 'Benin',
    label: 'Benin',
  },
  {
    value: 'Bermuda',
    label: 'Bermuda',
  },
  {
    value: 'Bhutan',
    label: 'Bhutan',
  },
  {
    value: 'Bolivia',
    label: 'Bolivia',
  },
  {
    value: 'Bosnia & Herzegovina',
    label: 'Bosnia & Herzegovina',
  },
  {
    value: 'Botswana',
    label: 'Botswana',
  },
  {
    value: 'Brazil',
    label: 'Brazil',
  },
  {
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
  },
  {
    value: 'Burkina Faso',
    label: 'Burkina Faso',
  },
  {
    value: 'Burundi',
    label: 'Burundi',
  },
  {
    value: 'Cambodia',
    label: 'Cambodia',
  },
  {
    value: 'Cameroon',
    label: 'Cameroon',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Cape Verde',
    label: 'Cape Verde',
  },
  {
    value: 'Cayman Islands',
    label: 'Cayman Islands',
  },
  {
    value: 'Central African Republic',
    label: 'Central African Republic',
  },
  {
    value: 'Chad',
    label: 'Chad',
  },
  {
    value: 'Chagos Archipelago',
    label: 'Chagos Archipelago',
  },
  {
    value: 'Chile',
    label: 'Chile',
  },
  {
    value: 'China',
    label: 'China',
  },
  {
    value: 'Colombia',
    label: 'Colombia',
  },
  {
    value: 'Comoros',
    label: 'Comoros',
  },
  {
    value: 'Congo',
    label: 'Congo',
  },
  {
    value: 'Congo, Dem. Rep. of',
    label: 'Congo, Dem. Rep. of',
  },
  {
    value: 'Cook Islands',
    label: 'Cook Islands',
  },
  {
    value: 'Costa Rica',
    label: 'Costa Rica',
  },
  {
    value: 'Côte d’lvoire',
    label: 'Côte d’lvoire',
  },
  {
    value: 'Croatia',
    label: 'Croatia',
  },
  {
    value: 'Cuba',
    label: 'Cuba',
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
  },
  {
    value: 'Czech Republic',
    label: 'Czech Republic',
  },
  {
    value: 'Denmark',
    label: 'Denmark',
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
  },
  {
    value: 'Dominica',
    label: 'Dominica',
  },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  {
    value: 'Ecuador',
    label: 'Ecuador',
  },
  {
    value: 'Egypt',
    label: 'Egypt',
  },
  {
    value: 'El Salvador',
    label: 'El Salvador',
  },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
  },
  {
    value: 'Estonia',
    label: 'Estonia',
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
  },
  {
    value: 'Faeroe Islands',
    label: 'Faeroe Islands',
  },
  {
    value: 'Falkland Islands',
    label: 'Falkland Islands',
  },
  {
    value: 'Fiji',
    label: 'Fiji',
  },
  {
    value: 'Finland',
    label: 'Finland',
  },
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'French Antilles',
    label: 'French Antilles',
  },
  {
    value: 'French Guiana',
    label: 'French Guiana',
  },
  {
    value: 'French Polynesia',
    label: 'French Polynesia',
  },
  {
    value: 'Gabon',
    label: 'Gabon',
  },
  {
    value: 'Gambia',
    label: 'Gambia',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Germany',
    label: 'Germany',
  },
  {
    value: 'Ghana',
    label: 'Ghana',
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    value: 'Greece',
    label: 'Greece',
  },
  {
    value: 'Greenland',
    label: 'Greenland',
  },
  {
    value: 'Grenada',
    label: 'Grenada',
  },
  {
    value: 'Guadeloupe',
    label: 'Guadeloupe',
  },
  {
    value: 'Guam',
    label: 'Guam',
  },
  {
    value: 'Guatemala',
    label: 'Guatemala',
  },
  {
    value: 'Guinea',
    label: 'Guinea',
  },
  {
    value: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
  },
  {
    value: 'Guyana',
    label: 'Guyana',
  },
  {
    value: 'Haiti',
    label: 'Haiti',
  },
  {
    value: 'Honduras',
    label: 'Honduras',
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
  },
  {
    value: 'Hungary',
    label: 'Hungary',
  },
  {
    value: 'Iceland',
    label: 'Iceland',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
  },
  {
    value: 'Iran',
    label: 'Iran',
  },
  {
    value: 'Iraq',
    label: 'Iraq',
  },
  {
    value: 'Ireland',
    label: 'Ireland',
  },
  {
    value: 'Israel',
    label: 'Israel',
  },
  {
    value: 'Italy',
    label: 'Italy',
  },
  {
    value: 'Ivory Coast',
    label: 'Ivory Coast',
  },
  {
    value: 'Jamaica',
    label: 'Jamaica',
  },
  {
    value: 'Japan',
    label: 'Japan',
  },
  {
    value: 'Jordan',
    label: 'Jordan',
  },
  {
    value: 'Kazakhstan',
    label: 'Kazakhstan',
  },
  {
    value: 'Kenya',
    label: 'Kenya',
  },
  {
    value: 'Kuwait',
    label: 'Kuwait',
  },
  {
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
  },
  {
    value: 'Laos',
    label: 'Laos',
  },
  {
    value: 'Latvia',
    label: 'Latvia',
  },
  {
    value: 'Lebanon',
    label: 'Lebanon',
  },
  {
    value: 'Lesotho',
    label: 'Lesotho',
  },
  {
    value: 'Liberia',
    label: 'Liberia',
  },
  {
    value: 'Libya',
    label: 'Libya',
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
  },
  {
    value: 'Macau',
    label: 'Macau',
  },
  {
    value: 'Macedonia',
    label: 'Macedonia',
  },
  {
    value: 'Madagascar',
    label: 'Madagascar',
  },
  {
    value: 'Malawi',
    label: 'Malawi',
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
  },
  {
    value: 'Maldives',
    label: 'Maldives',
  },
  {
    value: 'Mali',
    label: 'Mali',
  },
  {
    value: 'Malta',
    label: 'Malta',
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  {
    value: 'Martinique',
    label: 'Martinique',
  },
  {
    value: 'Mauritania',
    label: 'Mauritania',
  },
  {
    value: 'Mauritius',
    label: 'Mauritius',
  },
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: 'Midway Islands',
    label: 'Midway Islands',
  },
  {
    value: 'Moldova',
    label: 'Moldova',
  },
  {
    value: 'Monaco',
    label: 'Monaco',
  },
  {
    value: 'Mongolia',
    label: 'Mongolia',
  },
  {
    value: 'Montenegro & Serbia',
    label: 'Montenegro & Serbia',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'Morocco',
    label: 'Morocco',
  },
  {
    value: 'Mozambique',
    label: 'Mozambique',
  },
  {
    value: 'Myanmar',
    label: 'Myanmar',
  },
  {
    value: 'Namibia',
    label: 'Namibia',
  },
  {
    value: 'Nepal',
    label: 'Nepal',
  },
  {
    value: 'Netherlands',
    label: 'Netherlands',
  },
  {
    value: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
  },
  {
    value: 'New Caledonia',
    label: 'New Caledonia',
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
  },
  {
    value: 'Nicaragua',
    label: 'Nicaragua',
  },
  {
    value: 'Niger Republic',
    label: 'Niger Republic',
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
  },
  {
    value: 'North Korea',
    label: 'North Korea',
  },
  {
    value: 'Northern Mariana Isl.',
    label: 'Northern Mariana Isl.',
  },
  {
    value: 'Norway',
    label: 'Norway',
  },
  {
    value: 'Oman',
    label: 'Oman',
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
  },
  {
    value: 'Palau',
    label: 'Palau',
  },
  {
    value: 'Panama',
    label: 'Panama',
  },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
  },
  {
    value: 'Paraguay',
    label: 'Paraguay',
  },
  {
    value: 'Peru',
    label: 'Peru',
  },
  {
    value: 'Philippines',
    label: 'Philippines',
  },
  {
    value: 'Poland',
    label: 'Poland',
  },
  {
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico',
  },
  {
    value: 'Qatar',
    label: 'Qatar',
  },
  {
    value: 'Reunion Island',
    label: 'Reunion Island',
  },
  {
    value: 'Romania',
    label: 'Romania',
  },
  {
    value: 'Russia',
    label: 'Russia',
  },
  {
    value: 'Rwanda',
    label: 'Rwanda',
  },
  {
    value: 'San Marino',
    label: 'San Marino',
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
  },
  {
    value: 'Senegal',
    label: 'Senegal',
  },
  {
    value: 'Seychelles',
    label: 'Seychelles',
  },
  {
    value: 'Sierra Leone',
    label: 'Sierra Leone',
  },
  {
    value: 'Singapore',
    label: 'Singapore',
  },
  {
    value: 'Slovak Republic',
    label: 'Slovak Republic',
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
  },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
  },
  {
    value: 'Somalia',
    label: 'Somalia',
  },
  {
    value: 'South Africa',
    label: 'South Africa',
  },
  {
    value: 'South Korea',
    label: 'South Korea',
  },
  {
    value: 'Spain',
    label: 'Spain',
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka',
  },
  {
    value: 'St. Kitts & Nevis',
    label: 'St. Kitts & Nevis',
  },
  {
    value: 'St. Lucia',
    label: 'St. Lucia',
  },
  {
    value: 'St. Vincents & Grenadines',
    label: 'St. Vincents & Grenadines',
  },
  {
    value: 'Sudan',
    label: 'Sudan',
  },
  {
    value: 'Suriname',
    label: 'Suriname',
  },
  {
    value: 'Swaziland',
    label: 'Swaziland',
  },
  {
    value: 'Sweden',
    label: 'Sweden',
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
  },
  {
    value: 'Syria',
    label: 'Syria',
  },
  {
    value: 'Sใo Tom้ & Principe',
    label: 'Sใo Tom้ & Principe',
  },
  {
    value: 'Taiwan',
    label: 'Taiwan',
  },
  {
    value: 'Tajikistan',
    label: 'Tajikistan',
  },
  {
    value: 'Tanzania',
    label: 'Tanzania',
  },
  {
    value: 'Thailand',
    label: 'Thailand',
  },
  {
    value: 'Togo',
    label: 'Togo',
  },
  {
    value: 'Tonga',
    label: 'Tonga',
  },
  {
    value: 'Trinidad & Tobago',
    label: 'Trinidad & Tobago',
  },
  {
    value: 'Tunisia',
    label: 'Tunisia',
  },
  {
    value: 'Turkey',
    label: 'Turkey',
  },
  {
    value: 'Turkmenistan',
    label: 'Turkmenistan',
  },
  {
    value: 'Turks & Caicos Islands',
    label: 'Turks & Caicos Islands',
  },
  {
    value: 'Tuvalu',
    label: 'Tuvalu',
  },
  {
    value: 'Uganda',
    label: 'Uganda',
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    value: 'United States',
    label: 'United States',
  },
  {
    value: 'Uruguay',
    label: 'Uruguay',
  },
  {
    value: 'Uzbekistan',
    label: 'Uzbekistan',
  },
  {
    value: 'Vanuatu',
    label: 'Vanuatu',
  },
  {
    value: 'Venezuela',
    label: 'Venezuela',
  },
  {
    value: 'Vietnam',
    label: 'Vietnam',
  },
  {
    value: 'Virgin Islands, British',
    label: 'Virgin Islands, British',
  },
  {
    value: 'Virgin Islands, U.S.',
    label: 'Virgin Islands, U.S.',
  },
  {
    value: 'Western Samoa',
    label: 'Western Samoa',
  },
  {
    value: 'Yemen',
    label: 'Yemen',
  },
  {
    value: 'Yugoslavia',
    label: 'Yugoslavia',
  },
  {
    value: 'Zaire',
    label: 'Zaire',
  },
  {
    value: 'Zambia',
    label: 'Zambia',
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
  },
];

export const CURRENCY: OptionInfo<string>[] = [
  { value: 'USD', label: 'USD' },
  { value: 'HKD', label: 'HKD' },
  { value: 'SGD', label: 'SGD' },
];

export const ENTITY_ENDING_INFOS_US: OptionInfo<string>[] = [
  { value: 'LLC', label: 'LLC' },
];

export const ENTITY_ENDING_HK: OptionInfo<string>[] = [
  { value: 'Limited', label: 'Limited' },
];

export const ENTITY_ENDING_SING: OptionInfo<string>[] = [
  { value: 'Pte. Ltd', label: 'Pte. Ltd' },
];