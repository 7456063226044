import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callApiGetUserProfile, callApiStartKyc } from "../../api/account";
import { IconSpinner } from "../../components/icons";
import { RoutePaths } from "../../constants/routerPaths";
import { AuthContext } from "../../contexts/AuthContextProvider";
import KYCUploadContentManual from "./KYCUploadContentManual";

type Props = {};

export default function KYCUploadContentWrap(props: Props) {
  const { user, saveAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isStartingKyc, setIsStartingKyc] = useState(false);
  const [isAutoKyc, setIsAutoKyc] = useState(false);
  const [autoKycIframeUrl, setAutoKycIframeUrl] = useState<string>();

  useEffect(() => {
    if (user?.llcInNation === "United States") {
      setIsStartingKyc(true);
      callApiStartKyc()
        .then((result) => {
          if (result?.link?.url) {
            setIsAutoKyc(true);
            setAutoKycIframeUrl(result.link.url);
          } else {
            setIsAutoKyc(false);
          }
        })
        .catch(() => setIsAutoKyc(false))
        .finally(() => setIsStartingKyc(false));
    } else {
      setIsAutoKyc(false);
    }
  }, []);

  useEffect(() => {
    return updateUserProfile;
  }, []);

  function updateUserProfile() {
    callApiGetUserProfile().then((user) => saveAuthUser(user));
  }

  if (!user?.kycStatus || !["Pending", "Rejected"].includes(user?.kycStatus)) {
    navigate(RoutePaths.myAccount);
  }

  if (isStartingKyc) {
    return (
      <div className={"w-full h-full flex justify-center items-center"}>
        <IconSpinner className={"w-16 h-16 text-gray-400"} />
      </div>
    );
  }

  if (isAutoKyc && autoKycIframeUrl) {
    return <iframe src={autoKycIframeUrl} className={"w-full h-[1080px]"} />;
  }

  return <KYCUploadContentManual />;
}
